import React, { useEffect, useState, useRef } from "react";
import CustomForm from "../../components/CustomForm";
import {
  Tabs,
  Form,
  Input,
  Select,
  Button,
  Divider,
  Popconfirm,
  Popover,
  Space,
} from "antd";
import { useForm, useWatch } from "antd/es/form/Form";
import Emitter from "../../utils/emitter";
import { ExclamationCircleOutlined, WarningOutlined } from "@ant-design/icons";
import FormButtonSave from "../../components/FormButtonSave";
import FormButtonCancel from "../../components/FormButtonCancel";
import { GetAntIcon } from "../../utils/ant_icons";

const EmployeeConfiguration = (props: any) => {
  const currentProject = props.currentProject;
  const data = props.data || [];
  const setFormReset = props.setFormReset;
  const formRef = props.formRef;
  const positionData = props.positionData;
  const employeeType = props.employeeType;
  const activeRowKey = props.activeRowKey;
  const endDateVisibility = props.endDateVisibility;
  const setEndDateVisibility = props.setEndDateVisibility;

  // const [popOverVisibility, setPopOverVisibility] = useState<boolean>(false);
  // const [extraData, setExtraData] = useState<any[]>([]);
  // const [projectData, setProjectData] = useState<any>(null);

  // // const [initialForm, setInitialForm] = useState<any>(null);
  // const initialForm = useRef(null);

  // useEffect(() => {
  // 	// set project data
  // 	if (currentProject != "Add new project" && currentProject) {
  // 		let project = data.projects.filter(
  // 			(item: any) => item.project_code == currentProject
  // 		);
  // 		setProjectData(project[0]);
  // 		let initialFormData: any = {
  // 			project_manager: project[0].project_manager,
  // 			role_assignments: project[0].role_assignments,
  // 		};
  // 		initialForm.current = initialFormData;
  // 		formRef.setFieldsValue(initialForm.current);
  // 	}
  // }, [formRef, data]);

  // const handleFormCancel = (values: any, type: string) => {
  // 	console.log("cancel items", values);
  // };

  // const handleFormSave = (values: any, type: string) => {
  // 	Object.keys(values).forEach((key) => {
  // 		if (typeof values[key] == "string") {
  // 			values[key] = values[key].trim();
  // 		}
  // 	});

  // 	// 	console.log("save items", values);
  // 	Emitter.emit("loading", null);

  // 	// if (currentProject != "Add new project" && currentProject) {
  // 	// 	updateEmployeeItem(currentProject, values)
  // 	// 		.then(() => {
  // 	// 			Emitter.emit("alert", {
  // 	// 				type: "success",
  // 	// 				message: "Project has been successfully updated.",
  // 	// 				description: "",
  // 	// 				top: true,
  // 	// 				closeable: false,
  // 	// 				timeout: 3000,
  // 	// 			});
  // 	// 			resetForm(values);
  // 	// 			props?.handleProjectConfigSave();
  // 	// 		})
  // 	// 		.finally(() => {
  // 	// 			Emitter.emit("finish-loading", null);
  // 	// 		});
  // 	// }
  // };

  // const handleFormDelete = (values: any, type: string) => {
  // 	console.log("delete items", values);
  // };

  // const resetForm = (values: any = null, data: any = null) => {
  // 	let filteredCountries: any = [];
  // 	if (formRef) {
  // 		setFormReset(true);
  // 		if (values) {
  // 			Object.keys(values).forEach((key: any) => {
  // 				// values[key] = checkLookup(key, values[key], data || allData);
  // 				console.log(key, values[key]);
  // 			});
  // 		}
  // 		formRef.resetFields();
  // 		formRef.setFieldsValue(values);

  // 		initialForm.current = values;
  // 	}
  // };
  return (
    <>
      <div className="generic-header" style={{ height: "50px" }}>
        Employee Configurations
      </div>
      <div className="generic-content" style={{ flex: "1" }}>
        <div style={{ flex: "1", position: "relative" }}>
          {/* {currentProject !== "Add new project" && (
						// Show the project code if it's not a new project
						<span
							style={{
								position: "absolute",
								right: 0,
								color: "rgba(255, 255, 255, 0.5)",
							}}
						>
							# {currentProject}
						</span>
					)} */}
          <CustomForm
            tabKey={"employee"}
            employeeType={employeeType}
            activeRowKey={activeRowKey}
            formRef={formRef}
            dataTable={data}
            positionData={positionData}
            endDateVisibility={endDateVisibility}
            setEndDateVisibility={setEndDateVisibility}
            setFormReset={setFormReset}
          />
        </div>
      </div>
    </>
  );
};

export default EmployeeConfiguration;
