import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { serverConnection, url } from "../utils/_exports";
import {
  Button,
  Space,
  Table,
  Tabs,
  Tag,
  Dropdown,
  Modal,
  Form,
  Input,
  DatePicker,
  Upload,
  message,
  Divider,
  Select,
  Spin,
} from "antd";

import TextArea from "antd/es/input/TextArea";
import { useForm, useWatch } from "antd/es/form/Form";

import type { ColumnsType } from "antd/es/table";
import {
  getAddButtonProp,
  getColumnList,
  getLookupItem,
  getFormOptionList,
} from "../utils/lookup_list";
import Emitter from "../utils/emitter";
import { HomeContext } from "./Home";
import { useNavigate } from "react-router";
import {
  ConsoleSqlOutlined,
  UploadOutlined,
  InfoCircleOutlined,
  DeleteOutlined,
  FileTextOutlined,
} from "@ant-design/icons";
import { updateLeaveItem, addLeaveItem } from "../services/api-server/leave";
import { uploadFile, downloadFile } from "../services/api-server/filetransfer";
import { updateEmployeeItem } from "../services/api-server/employee";
import { updateClaimBalance } from "../services/api-server/claimbalance";
import { daysCalculation } from "../utils/utils";
import { set, update } from "lodash";
import Item from "antd/es/list/Item";
import { v4 as uuidv4 } from "uuid";
import { getLoanListByField } from "../services/api-server/loan";
import { calc } from "antd/es/theme/internal";
// import { LeaveConfiguration } from "./LeaveConfiguration";
import {
  generateLoanReport,
  deleteLoanItem,
} from "../services/api-server/loan";
import {
  updateExcelItem,
  deleteExcelItem,
} from "../services/api-server/excel_file";
import LeaveApprovalForm from "../components/LeaveApprovalForm";
import LeaveApplication from "./LeaveApplication";
import { color } from "d3";
import { OtherHouses } from "@mui/icons-material";
import moment from "moment";
import { sendEmail } from "../services/api-server/usertoken";
import { socket } from "../utils/socket";
import { isMobile } from "react-device-detect";
import CustomForm from "../components/CustomForm";
import dayjs from "dayjs";

const LookupManager = (props: any) => {
  const context: any = useContext(HomeContext);
  const navigate: any = useNavigate();
  const { Option } = Select;
  const [formRef]: any = useForm();
  const role = props?.role;
  const userData = props?.userData;
  const [tabKey, setTabKey] = useState<any>(props.tabKey);
  const [subTabKey, setSubTabKey] = useState<any>(props.subTabKey || null);
  const containerTitle = props?.containerTitle;
  const [tableColumns, setTableColumns] = useState<ColumnsType<any>>([]);
  const [dataTable, setDataTable] = useState<any[]>([]);
  const [dbData, setDbData] = useState<any>({});
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [selectedExcel, setSelectedExcels] = useState([]);
  const [filteredDataTable, setFilteredDataTable] = useState<any>(null);
  const [formReset, setFormReset] = useState<boolean>(true);
  const [initialForm, setInitialForm] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [sideContainerOpen, setSideContainerOpen] = useState<any>(
    props.sideContainerOpen || false
  );
  const [activeRowKey, setActiveRowKey] = useState<any>(
    props.activeRowKey || null
  );
  const [activeRecord, setActiveRecord] = useState<any>(
    props.activeRecord || null
  );
  const [expandedRowKeys, setExpandRowKey] = useState<any>([]);
  const [holidayData, setHolidayData] = useState<any>(
    props.holidayData || null
  );
  const [workdayData, setWorkdayData] = useState<any>(
    props.workdayData || null
  );
  const [reportungroupcompanyData, setReportUngroupCompanyData] = useState<any>(
    []
  );
  const [reportcompanyData, setReportCompanyData] = useState<any>([]);
  const [reportproductData, setReportProductData] = useState<any>([]);
  const [positionData, setPositionData] = useState<any>([]);
  const [addButtonProp, setAddButtonProp] = useState<any>(null);
  const [searchRecord, setSearchRecord] = useState<any>([]);
  const [tempRecord, setTempRecord] = useState<any>(null);

  const [tableHeight, setTableHeight] = useState<any>(null);
  const [ready, setReady] = useState<any>(false);
  const [generatereportform] = Form.useForm();

  const [activeGroupKey, setActiveGroupKey] = useState<any>(null);

  const [showRejectionModal, setShowRejectionModal] = useState<any>(false);
  const [rejectionModalStatus, setRejectionModalStatus] = useState<any>("");
  const [rejectionReason, setRejectionReason] = useState<any>("");

  const [showLeaveApplication, setShowLeaveApplication] = useState<any>(false);
  const [leaveform] = Form.useForm();
  const [refreshData, setRefreshData] = useState<any>(false);

  const [imgNameUUIDList, setImgNameUUIDList] = useState<any>([]);

  const [openModal, setOpenModal] = useState<any>(true);
  const [generate_reports_modal_visible, setGenerateReportsModalVisible] =
    useState(false);
  const [delete_excel_modal_visible, setDeleteExcelModalVisible] =
    useState(false);

  const currentDate = new Date();

  const url = process.env.REACT_APP_SOCKET_SERVER as string;

  // useEffect(() => {
  //   // positionData
  //   getLookupItem("position")
  //     .then((data: any) => {
  //       setPositionData(data);
  //     })
  //     .catch((error: any) => console.log(error));
  // }, [tempRecord]);

  useEffect(() => {
    if (searchRecord && tempRecord) {
      let recordIndex = searchRecord.findIndex((item: any) => {
        return item.columnKey === tempRecord.columnKey;
      });
      if (recordIndex !== -1) {
        if (tempRecord.value != "") {
          let _record = [...searchRecord];
          _record[recordIndex] = tempRecord;
          setSearchRecord(_record);
        } else {
          let _record = [...searchRecord];
          _record.splice(recordIndex, 1);
          setSearchRecord(_record);
        }
      } else {
        if (tempRecord.value != "") {
          let _record = [...searchRecord];
          _record.push(tempRecord);
          setSearchRecord(_record);
        }
      }
    }
  }, [tempRecord]);

  useEffect(() => {
    let companygroupby = [
      "company_name",
      "branch_name",
      "report_date",
      "companyid",
      "branchid",
    ];
    let companytarget: { [key: string]: string } = {};
    companytarget["groupby"] = companygroupby.join(",");

    getLoanListByField(companytarget)
      .then((data: any) => {
        const reformattedData = data.map((item: any) => {
          return {
            company_name: item._id.company_name,
            branch_name: item._id.branch_name,
            report_date: item._id.report_date,
            companyid: item._id.companyid,
            branchid: item._id.branchid,
          };
        });
        console.log("reformattedData", reformattedData);
        setReportUngroupCompanyData(reformattedData);

        // console.log("reformattedData", reformattedData);
        const groupedData: { key: string } = reformattedData.reduce(
          (acc: any, entry: any) => {
            const date = entry.report_date.split("T")[0];
            if (!acc[date]) {
              acc[date] = [];
            }
            acc[date].push(entry);
            return acc;
          },
          {} as { key: string }
        );

        setReportCompanyData(groupedData);
      })
      .catch((error: any) => console.log(error));

    let productgroupby = ["sub_acct_type", "report_date"];
    let producttarget: { [key: string]: string } = {};
    producttarget["groupby"] = productgroupby.join(",");

    getLoanListByField(producttarget)
      .then((data: any) => {
        const reformattedData = data.map((item: any) => {
          return {
            sub_acct_type: item._id.sub_acct_type,
            report_date: item._id.report_date,
          };
        });

        const groupedData: { key: string } = reformattedData.reduce(
          (acc: any, entry: any) => {
            const date = entry.report_date.split("T")[0];
            if (!acc[date]) {
              acc[date] = [];
            }
            acc[date].push(entry);
            return acc;
          },
          {} as { key: string }
        );

        setReportProductData(groupedData);
      })
      .catch((error: any) => console.log(error));
  }, []);

  useEffect(() => {
    refresh_excel_file();
    // console.log("searchRecord activated: ", searchRecord);
    if (searchRecord?.length > 0) {
      //console.log("Search record testing", searchRecord);
      let data: any = [...dataTable];

      // if (tabKey == "employee") {
      //     data = [...dataTable];
      // }

      let filteredData = data
        .map((element: any) => {
          let bool = searchRecord?.every((item: any) => {
            if (item.columnKey === "leave_date") {
              const filteredDates = element["date_range"]?.filter(
                (date: any) => {
                  const dateObject = new Date(date);
                  const day = String(dateObject.getDate()).padStart(2, "0"); // Get day with leading zero
                  const month = String(dateObject.getMonth() + 1).padStart(
                    2,
                    "0"
                  ); // Get month with leading zero
                  const year = String(dateObject.getFullYear()); // Get full year

                  // Check if any part of the date contains "04"
                  return (
                    day.includes(item.value) ||
                    month.includes(item.value) ||
                    year.includes(item.value) ||
                    moment(dateObject)
                      .format("DD MMM YYYY")
                      .toLowerCase()
                      .includes(item.value.toLowerCase())
                  );
                }
              );
              return filteredDates.length > 0; // Return true if there are dates containing the specified value
            } else {
              return element[item.columnKey]
                ?.toString()
                ?.toLowerCase()
                .includes(item.value.toLowerCase());
            }
          });
          if (bool) return element;
        })
        .filter((element: any) => element !== null && element !== undefined);
      setFilteredDataTable(filteredData);
    } else {
      setFilteredDataTable(null);
    }
  }, [searchRecord, refreshData]);

  // Reset form
  const resetForm = (values: any = null, data: any = null) => {
    if (formRef) {
      setFormReset(true);
      formRef.resetFields();
      formRef.setFieldsValue(values);
      // console.log(values);
      setInitialForm(values);
    }
  };

  const handleSearch = (record: any) => {
    setTempRecord(record);
  };

  const handleTabChange = async (key: any) => {
    try {
      await context.handlePageChange();
      setTableColumns([]);
      setDataTable([]);
      setSideContainerOpen(false);
      setSubTabKey(key);
      setExpandRowKey([]);
      setActiveRowKey(null);
      setActiveGroupKey(null);
    } catch {}
  };

  const calculateTableHeight = () => {
    const table: any = document.getElementById("table-container");
    if (table) {
      setTableHeight(table?.clientHeight - 65 - 64);
    }
  };

  const extractDateFromFilename = (filename: any) => {
    const match = filename.match(/\[(\d{4}-\d{2})\]/);
    return match ? match[1] : null; // Return the [YYYY-MM] format if found, otherwise null
  };

  const handleGenerateReportsModalCreate = () => {
    const matched_companyids: any = [];
    const matched_branchids: any = [];
    formRef
      .validateFields()
      .then((values: any) => {
        const formattedDate = dayjs(
          formRef.getFieldValue("report_date")
        ).format("MM-YYYY");
        const selectedCompany = formRef.getFieldValue("company_name");
        if (Array.isArray(selectedCompany) && selectedCompany.includes("all")) {
          console.log("all");
        } else {
          reportungroupcompanyData.forEach((record: any) => {
            if (selectedCompany.includes(record.companyid)) {
              matched_companyids.push(record.companyid);
            } else if (selectedCompany.includes(record.branchid)) {
              matched_branchids.push(record.branchid);
            }
          });
        }

        const unique_companyids = Array.from(
          new Map(matched_companyids.map((id: any) => [id, id])).values()
        );
        const unique_branchids = Array.from(
          new Map(matched_branchids.map((id: any) => [id, id])).values()
        );
        const payload = {
          ...formRef.getFieldsValue(),
          companyid: unique_companyids,
          branchid: unique_branchids,
        };
        setTimeout(() => {
          setLoading(true);
          generateLoanReport(formattedDate, payload)
            .then((data: any) => {
              // console.log(data);
              Emitter.emit("alert", {
                type: "success",
                message: "Excel has been generated.",
                description: "",
                top: true,
                closeable: false,
                timeout: 3000,
              });
              // Filter datatable by selectedkeyrow and add report_generated_date
              const updatedData = dataTable
                .filter(
                  (item) =>
                    moment(item.grouped_date).format("MM-YYYY") ===
                    formattedDate
                )
                .map((item) => ({
                  _id: item._id,
                  report_generated_date: moment().format("YYYY-MM-DD HH:mm:ss"),
                }));

              updateExcelItem(updatedData)
                .then((data: any) => {
                  // Emitter.emit("alert", {
                  //   type: "success",
                  //   message: "Position has been successfully updated.",
                  //   description: "",
                  //   top: true,
                  //   closeable: false,
                  //   timeout: 3000,
                  // });
                  // setSideContainerOpen(false);
                  socket.emit(
                    "excel_datatable_update",
                    `Hello from React frontend! ${socket.id}`
                  );
                  setGenerateReportsModalVisible(false);
                  setLoading(false);
                })
                .catch((e: any) => {
                  //console.log("error", e);
                  setLoading(false);
                });
            })
            .catch((errorInfo: any) => {
              console.log("Validation failed:", errorInfo);
            });
        }, 50);
      })
      .catch((errorInfo: any) => {
        console.log("Validation failed:", errorInfo);
      });
  };
  // console.log("formRef.validateFields();", formRef.validateFields());
  // console.log("ddd", formRef.getFieldsValue());
  // const formattedDate = dayjs(formRef.getFieldValue("report_date")).format(
  //   "MM-YYYY"
  // );
  // generateLoanReport(formattedDate, formRef.getFieldsValue())
  //   .then((data: any) => {
  //     // console.log(data);
  //     Emitter.emit("alert", {
  //       type: "success",
  //       message: "Excel has been generated.",
  //       description: "",
  //       top: true,
  //       closeable: false,
  //       timeout: 3000,
  //     });
  //     // Filter datatable by selectedkeyrow and add report_generated_date
  //     // const updatedData = dataTable
  //     //   .filter((item) => groupedData[date].includes(item.uuid))
  //     //   .map((item) => ({
  //     //     _id: item._id,
  //     //     report_generated_date: moment().format("YYYY-MM-DD HH:mm:ss"),
  //     //   }));
  //     // updateExcelItem(updatedData)
  //     //   .then((data: any) => {
  //     //     // Emitter.emit("alert", {
  //     //     //   type: "success",
  //     //     //   message: "Position has been successfully updated.",
  //     //     //   description: "",
  //     //     //   top: true,
  //     //     //   closeable: false,
  //     //     //   timeout: 3000,
  //     //     // });
  //     //     // setSideContainerOpen(false);
  //     //     socket.emit(
  //     //       "excel_datatable_update",
  //     //       `Hello from React frontend! ${socket.id}`
  //     //     );
  //     //     setGenerateReportsModalVisible(false);
  //     //   })
  //     //   .catch((e: any) => {
  //     //     //console.log("error", e);
  //     //   });
  //   })
  //   .catch((e: any) => {
  //     //console.log("error", e);
  //   })
  //   .finally(() => {
  //     // Emitter.emit("finish-loading", null);
  //     // setRefreshData(!refreshData);
  //     // setGenerateReportsModalVisible(false);
  //     // context?.handleFormUpdating(false);
  //     // socket.emit("pong-update", () => {
  //     //   console.log("Pinging server");
  //     // });
  //   });
  // if (selectedRowKeys.length > 0) {
  //   // const selectedRowKeysWithoutExtension = selectedRowKeys.map((file: any) =>
  //   //   file.replace(".xlsx", "")
  //   // );
  //   const filteredData = dataTable.filter((item) =>
  //     selectedRowKeys.includes(item.key)
  //   );
  //   // console.log("selectedRowKeys", selectedRowKeys);
  //   // console.log("datatable", dataTable);
  //   const groupedData = filteredData.reduce((acc, item) => {
  //     const date = extractDateFromFilename(item.filename);

  //     if (date) {
  //       // If the group doesn't exist for this date, create an array for it
  //       if (!acc[date]) {
  //         acc[date] = [];
  //       }
  //       // Add the item to the corresponding date group
  //       acc[date].push(item.uuid);
  //     }

  //     return acc;
  //   }, {});
  //   Object.keys(groupedData).forEach((date: any) => {
  //     // console.log(`Date: ${date}`);
  //     // groupedData[date].forEach((uuid:any) => {
  //     //     console.log(`UUID: ${uuid}`);
  //     // });
  //     const [year, month] = date.split("-"); // Split the string into year and month
  //     const formattedDate = `${month}-${year}`; // Rearrange into MM-YYYY
  //     generateLoanReport(formattedDate, groupedData[date])
  //       .then((data: any) => {
  //         // console.log(data);
  //         Emitter.emit("alert", {
  //           type: "success",
  //           message: "Excel has been generated.",
  //           description: "",
  //           top: true,
  //           closeable: false,
  //           timeout: 3000,
  //         });
  //         // Filter datatable by selectedkeyrow and add report_generated_date
  //         const updatedData = dataTable
  //           .filter((item) => groupedData[date].includes(item.uuid))
  //           .map((item) => ({
  //             _id: item._id,
  //             report_generated_date: moment().format("YYYY-MM-DD HH:mm:ss"),
  //           }));
  //         updateExcelItem(updatedData)
  //           .then((data: any) => {
  //             // Emitter.emit("alert", {
  //             //   type: "success",
  //             //   message: "Position has been successfully updated.",
  //             //   description: "",
  //             //   top: true,
  //             //   closeable: false,
  //             //   timeout: 3000,
  //             // });
  //             // setSideContainerOpen(false);
  //             socket.emit(
  //               "excel_datatable_update",
  //               `Hello from React frontend! ${socket.id}`
  //             );
  //             setGenerateReportsModalVisible(false);
  //           })
  //           .catch((e: any) => {
  //             //console.log("error", e);
  //           });
  //       })
  //       .catch((e: any) => {
  //         //console.log("error", e);
  //       })
  //       .finally(() => {
  //         // Emitter.emit("finish-loading", null);
  //         // setRefreshData(!refreshData);
  //         // setGenerateReportsModalVisible(false);
  //         // context?.handleFormUpdating(false);
  //         // socket.emit("pong-update", () => {
  //         //   console.log("Pinging server");
  //         // });
  //       });
  //   });
  // } else {
  //   Emitter.emit("alert", {
  //     type: "error",
  //     message: "There are no excel selected!",
  //     description: "",
  //     top: true,
  //     closeable: false,
  //     timeout: 3000,
  //   });
  // }
  // };

  const handleDeleteExcelConfirm = () => {
    if (selectedRowKeys.length > 0) {
      const uuidValue = `in.(${selectedRowKeys.join(",")})`;

      let deleted_uuid = {
        uuid: uuidValue,
      };

      let deleted_excel_id = {
        excel_id: uuidValue,
      };
      deleteExcelItem(deleted_uuid)
        .then((data: any) => {
          // console.log(data);
          // Emitter.emit("alert", {
          //   type: "success",
          //   message: "Excel has been generated.",
          //   description: "",
          //   top: true,
          //   closeable: false,
          //   timeout: 3000,
          // });
          // Filter datatable by selectedkeyrow and add report_generated_date
          deleteLoanItem(deleted_excel_id)
            .then((data: any) => {
              Emitter.emit("alert", {
                type: "success",
                message: "Excel has been generated.",
                description: "",
                top: true,
                closeable: false,
                timeout: 3000,
              });
              // setSideContainerOpen(false);
              socket.emit(
                "excel_datatable_update",
                `Hello from React frontend! ${socket.id}`
              );
              setDeleteExcelModalVisible(false);
              setSelectedRowKeys([]);
            })
            .catch((e: any) => {
              //console.log("error", e);
            });
        })
        .catch((e: any) => {
          //console.log("error", e);
        })
        .finally(() => {
          // Emitter.emit("finish-loading", null);
          // setRefreshData(!refreshData);
          // setGenerateReportsModalVisible(false);
          // context?.handleFormUpdating(false);
          // socket.emit("pong-update", () => {
          //   console.log("Pinging server");
          // });
        });
    } else {
      Emitter.emit("alert", {
        type: "error",
        message: "There are no excel selected!",
        description: "",
        top: true,
        closeable: false,
        timeout: 3000,
      });
    }
  };

  const setSideContainer = () => {
    if (sideContainerOpen) {
      return (
        <div
          className="lookup-side-container"
          style={{
            width: isMobile ? "100%" : "35%",
            maxHeight: "100%",
            // margin: "10px",
            overflow: "hidden",
            display: "flex",
            flexDirection: "column",
            borderRadius: "3px",
          }}
        >
          {/* <Modal
          open={openModal}
          width={isMobile ? "100%" : "35%"}
          style={{ top: 20, borderRadius: "3px" }}
          closable={false}
          footer={null}
        > */}
          <div className="lookup-side-header" style={{ height: "50px" }}>
            <span
              style={{
                marginRight: "auto",
                minHeight: "32px",
                display: "flex",
                alignItems: "center",
                color: "#ffffff",
              }}
            >
              {/* {tabKey.charAt(0).toUpperCase() +
                                tabKey.split("_").join(" ").slice(1)}{" "} */}
              Leave details
            </span>
            <button
              onClick={() => {
                setSideContainerOpen(false);
                setActiveRowKey(null);
                setActiveGroupKey(null);
              }}
              style={{
                color: "rgba(255, 255, 255, 1)",
                fontSize: "15px",
                // fontWeight: "600",
                backgroundColor: "transparent",
                border: "none",
                cursor: "pointer",
              }}
            >
              X
            </button>
          </div>
          <div
            className="generic-content"
            style={{
              flex: "1",
              gap: "0px",
              maxHeight: "100%",
              overflow: "auto",
              flexWrap: "nowrap",
              backgroundColor: "#ffffff",
              border: "solid 1px",
              borderLeftColor: "#A7A7A7",
              borderRightColor: "#A7A7A7",
            }}
          >
            {sideContainerOpen && (
              <LeaveApprovalForm
                setTabKey={setTabKey}
                data={activeRecord}
                tabKey={tabKey}
                subTabKey={subTabKey}
                holidayData={holidayData}
                workdayData={workdayData}
              />
            )}
          </div>
          <div className="generic-footer" style={{ height: "50px" }}>
            {activeRecord.status == "pending" && (
              <div>
                <Button
                  onClick={() => {
                    setRejectionModalStatus("rejected");
                    setShowRejectionModal(true);
                  }}
                  className="ant-btn-secondary"
                >
                  Reject
                </Button>{" "}
                <Button
                  // onClick={() => {
                  //   handleApproval(activeRecord);
                  // }}
                  type="primary"
                  htmlType="submit"
                >
                  Approve
                </Button>
              </div>
            )}
            {activeRecord.status == "approved" &&
              currentDate < new Date(activeRecord.start_date) && (
                <div>
                  <Button
                    onClick={() => {
                      setRejectionModalStatus("cancelled");
                      setShowRejectionModal(true);
                    }}
                    type="primary"
                    htmlType="submit"
                  >
                    Cancel Leave
                  </Button>
                </div>
              )}
            {(activeRecord.status == "rejected" ||
              activeRecord.status == "cancelled" ||
              currentDate > new Date(activeRecord.start_date)) &&
              activeRecord.status !== "pending" && (
                <div>
                  <Button
                    onClick={() => {
                      setSideContainerOpen(false);
                    }}
                    type="primary"
                    htmlType="submit"
                  >
                    Close
                  </Button>
                </div>
              )}
          </div>
        </div>
      );
    }
  };

  useEffect(() => {
    socket.on("update_report_datatable", () => {
      refresh_excel_file();
    });
  }, []);

  const refresh_excel_file = () => {
    calculateTableHeight();
    Promise.all([getLookupItem("excel")]).then((data: any) => {
      const object: any = {};
      setDbData(object);
    });
    setReady(false);
    window.addEventListener("resize", () => {
      calculateTableHeight();
    });
    getLookupItem(tabKey)
      .then((_data: any) => {
        let full_data = _data
          ?.slice()
          .reverse()
          .map((data: any) => {
            return {
              ...data,
              key: data.uuid,
            };
          });
        // console.log("full_data", full_data);
        setDataTable(full_data);
      })
      .catch((error: any) => {
        console.error("Error fetching data: ", error);
      });
    let columns: any = getColumnList(tabKey, handleSearch, subTabKey);
    let renderedColumns = [];
    if (columns) {
      renderedColumns = columns.map((element: any) => {
        if (element.isActive == true) {
          element.render = (text: any, record: any) => {
            if (text) {
              return (
                <a
                  onClick={() => {
                    console.log(record.uuid);
                    downloadFile(record.filename, {
                      filename: record.uuid,
                      download_path: "../katimas_upload_excel",
                    });
                  }}
                  className="table-clickable-text"
                >
                  {text}
                </a>
              );
            }
          };
        }
        return element;
      });
      setTableColumns(renderedColumns);
    }
    setReady(true);
  };

  useEffect(() => {
    setReady(false);
    window.addEventListener("resize", () => {
      calculateTableHeight();
    });
    getLookupItem(tabKey)
      .then((_data: any) => {
        let full_data = _data
          ?.slice()
          .reverse()
          .map((data: any) => {
            return {
              ...data,
              key: data.uuid,
            };
          });
        // console.log("full_data", full_data);
        setDataTable(full_data);
      })
      .catch((error: any) => {
        console.error("Error fetching data: ", error);
      });
    let columns: any = getColumnList(tabKey, handleSearch, subTabKey);
    let renderedColumns = [];
    if (columns) {
      renderedColumns = columns.map((element: any) => {
        if (element.isActive == true) {
          element.render = (text: any, record: any) => {
            if (text) {
              return (
                <a
                  onClick={() => {
                    console.log(record.uuid);
                    downloadFile(record.filename, {
                      filename: record.uuid,
                      download_path: "../katimas_upload_excel",
                    });
                  }}
                  className="table-clickable-text"
                >
                  {text}
                </a>
              );
            }
          };
        }
        return element;
      });
      setTableColumns(renderedColumns);
    }
    setReady(true);
  }, []);

  const fetchData = () => {
    setReady(false);
    // window.addEventListener("resize", () => {
    //   calculateTableHeight();
    // });
    Emitter.emit("loading", null);
    let columns: any = getColumnList(tabKey, handleSearch, subTabKey);
    setAddButtonProp(getAddButtonProp(tabKey));

    const itemKey =
      tabKey === "employee"
        ? "employee"
        : tabKey === "leaveManager"
        ? "leave"
        : tabKey;

    getLookupItem(itemKey)
      .then((_data: any) => {
        let data: any = [];
        let key = columns[0].dataIndex;
        if (tabKey == "employee") {
          // console.log(_data);
          _data = _data?.map((item: any) => {
            const position = positionData?.find(
              (position_item: any) =>
                position_item.position_code === item.position
            );
            // console.log(item.position);
            delete item.position;
            return {
              ...item,
              position: position ? position.position_name : "-",
            };
          });

          if (subTabKey == "employee") {
            data = _data?.filter(
              (item: any) =>
                item.position != "Intern" &&
                item.position !== "INTERN" &&
                (item.status === "active" || item.status === undefined)
            );
          } else if (subTabKey == "intern") {
            data = _data?.filter(
              (item: any) =>
                item.position === "Intern" &&
                (item.status === "active" || item.status === undefined)
            );
          } else if (subTabKey == "archived") {
            data = _data?.filter((item: any) => item.status === "inactive");
          }
        } else if (tabKey == "leaveManager") {
          if (subTabKey == "all_leave") {
            data = _data;
          } else if (subTabKey == "pending") {
            data = _data.filter((item: any) => item.status == "pending");
          } else if (subTabKey == "approved") {
            data = _data.filter((item: any) => item.status == "approved");
          } else if (subTabKey == "rejected") {
            data = _data.filter((item: any) => item.status == "rejected");
          } else if (subTabKey == "cancelled") {
            data = _data.filter((item: any) => item.status == "cancelled");
          } else {
            data = _data;
          }
        } else {
          data = _data;
        }

        if (data) {
          data = data
            .map((element: any) => {
              return {
                ...element,
              };
            })
            .sort((a: any, b: any) => a[key].localeCompare(b[key]));

          data = tabKey === "leaveManager" ? data.reverse() : data;
          //console.log(data);
          if (tabKey === "leaveManager") {
            data = data.map((element: any) => {
              let duration = "0";
              if (element.days === 0 || !element.days) {
                duration = "0 day";
              } else {
                const duration_in_days: any = daysCalculation(
                  workdayData,
                  holidayData,
                  element.start_date,
                  element.end_date,
                  element.half_days
                );
                duration =
                  duration_in_days <= 1
                    ? `${duration_in_days} day`
                    : `${duration_in_days} days`;
              }

              return { ...element, duration: duration };
            });
          }

          // console.log("1-data11", data);
          let renderedColumns = [];

          if (columns) {
            renderedColumns = columns.map((element: any) => {
              if (element.isActive == true) {
                element.render = (text: any, record: any) => {
                  const id = record._id || record.uuid || record.group_uuid;
                  const employee_name = record.fullname_nric;
                  if (text) {
                    return (
                      <a
                        onClick={() => {
                          // console.log(record);
                          if (tabKey == "employee") {
                            navigate("configuration", {
                              replace: false,
                              state: {
                                tabKey,
                                subTabKey,
                                activeRowKey: id,
                                name: employee_name,
                              },
                            });
                          } else if (tabKey == "leaveManager") {
                            // console.log(
                            //     "showModal active"
                            // );
                            setSideContainerOpen(true);
                            setActiveRowKey(id);
                            setActiveRecord(record);
                            setHolidayData(holidayData);
                            setWorkdayData(workdayData);
                          }
                        }}
                        className="table-clickable-text"
                      >
                        {text}
                      </a>
                    );
                  }
                };
              }

              return element;
            });
            setTableColumns(renderedColumns);
            setDataTable(data);
          }
        } else {
          setTableColumns([]);
          setDataTable([]);
        }
      })
      .catch((e: any) => {
        //console.log(e);
        setTableColumns([]);
        setDataTable([]);
      })
      .finally(() => {
        setReady(true);
        // calculateTableHeight();
        setFilteredDataTable(null);
        setSearchRecord([]);
        setTempRecord(null);
        Emitter.emit("finish-loading", null);
      });
  };

  const rowSelection = {
    preserveSelectedRowKeys: true,
    selectedRowKeys,
    onChange: (newSelectedRowKeys: any, selectedRows: any) => {
      setSelectedRowKeys(newSelectedRowKeys);
      setSelectedExcels(selectedRows);
    },
  };

  const handleGenerateReportsClick = (record: any) => {
    // setSelectedEmployee(employeeData);
    setGenerateReportsModalVisible(true);
  };

  // Triggers the useState or modal to close when 'Cancel' button is being clicked
  const handleGenerateReportsModalCancel = () => {
    setGenerateReportsModalVisible(false);
    resetForm();
  };

  const handleDeleteExcelClick = (record: any) => {
    // setSelectedEmployee(employeeData);
    setDeleteExcelModalVisible(true);
  };

  // Triggers the useState or modal to close when 'Cancel' button is being clicked
  const handleDeleteExcelModalCancel = () => {
    setDeleteExcelModalVisible(false);
  };

  const getSubTabKeys = () => {
    if (tabKey == "employee") {
      return [
        {
          label: "Employee",
          key: "employee",
        },
        {
          label: "Intern",
          key: "intern",
        },
        {
          label: "Archived",
          key: "archived",
        },
      ];
    } else if (tabKey == "leaveManager") {
      return [
        {
          label: "All Leave",
          key: "all_leave",
        },
        {
          label: "Pending",
          key: "pending",
        },
        {
          label: "Approved",
          key: "approved",
        },
        {
          label: "Rejected",
          key: "rejected",
        },
        {
          label: "Cancelled",
          key: "cancelled",
        },
      ];
    } else {
      return [];
    }
  };

  return containerTitle !== "" ? (
    <div
      style={{
        flex: "1",
        display: "flex",
        flexDirection: "row",
        gap: "20px",
        maxHeight: "100%",
        overflow: "hidden",
      }}
    >
      <div
        className="main-container"
        style={{
          width: "100%",
          maxHeight: "100%",
          maxWidth: "100%", //70%
          overflow: "hidden",
          display: sideContainerOpen && isMobile ? "none" : "flex",
          flexDirection: "column",
          borderRadius: "3px",
        }}
      >
        <div className="generic-header" style={{ height: "50px" }}>
          {/* Configurations */}
          {/* {tabKey.charAt(0).toUpperCase() + tabKey.slice(1)} */}
          {containerTitle}
        </div>
        <div
          className="generic-content"
          style={{
            flex: "1",
            gap: "0px",
            maxHeight: "100%",
            maxWidth: "100%",
            overflow: "hidden",
            flexWrap: "nowrap",
            flexDirection: isMobile ? "column" : "row",
          }}
        >
          <Tabs
            style={{ width: isMobile ? "100%" : "auto" }}
            tabPosition={isMobile ? "top" : "left"}
            size={isMobile ? "small" : "large"}
            onChange={handleTabChange}
            activeKey={subTabKey}
            items={getSubTabKeys()}
          ></Tabs>
          <div
            style={{
              flex: "1",
              height: "100%",
              display: "flex",
              maxWidth: "100%",
              overflow: "hidden",
              flexDirection: "column",
            }}
          >
            {" "}
            <div
              className="generic-table-header"
              style={{ display: "flex", flexDirection: "row" }}
            >
              <span
                style={{
                  marginRight: "auto",
                  minHeight: "32px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {tabKey == "employee" &&
                  subTabKey.charAt(0).toUpperCase() +
                    subTabKey.split("_").join(" ").slice(1)}{" "}
                {tabKey == "leaveManager" &&
                  subTabKey.charAt(0).toUpperCase() +
                    subTabKey.split("_").join(" ").slice(1)}{" "}
                directory
              </span>

              {tabKey == "employee" &&
                (subTabKey === "employee" || subTabKey === "intern") && (
                  <Button
                    onClick={() => {
                      navigate("configuration", {
                        replace: false,
                        state: {
                          tabKey,
                          subTabKey,
                          name: `Add new ${subTabKey}`,
                        },
                      });
                    }}
                    // style={tabKey == "country" ? { display: "none" } : {}}
                    disabled={sideContainerOpen}
                  >
                    + Add
                  </Button>
                )}
              {tabKey == "leaveManager" && subTabKey == "all_leave" && (
                <Button
                  onClick={() => {
                    switch (tabKey) {
                      case "employee":
                        navigate("configuration", {
                          replace: false,
                          state: {
                            tabKey,
                            subTabKey,
                            name: `Add new ${subTabKey}`,
                          },
                        });
                        break;
                      case "leaveManager":
                        setShowLeaveApplication(true);
                        // handleLeaveApplication();
                        break;
                      default:
                        break;
                    }
                  }}
                >
                  + Add
                </Button>
              )}
            </div>
            <div
              id="table-container"
              style={{
                flex: 1,
                display: "flex",
                maxHeight: "100%",
                maxWidth: "100%",
                overflow: "hidden",
              }}
            >
              {ready && (
                <Table
                  rowClassName={(record: any) =>
                    record?.uuid === activeRowKey ||
                    `${record.group_uuid}-${record.tag_uuid}` ===
                      activeGroupKey ||
                    (record.group_uuid === activeRowKey && !record.uuid)
                      ? "antd-table-row-active"
                      : ""
                  }
                  pagination={{
                    defaultPageSize: 20,
                    pageSizeOptions: [20, 50, 100],
                    showSizeChanger: true,
                    position: ["bottomCenter"],
                  }}
                  columns={tableColumns}
                  expandable={{
                    expandedRowKeys: expandedRowKeys,
                    onExpand: (expand: any, record: any) => {
                      let keys = [...expandedRowKeys];
                      if (expand) {
                        keys.push(record.uuid);
                      } else {
                        let index = keys.findIndex(
                          (item: any) => item == record.uuid
                        );
                        if (index != -1) {
                          keys.splice(index, 1);
                        }
                      }
                      setExpandRowKey(keys);
                    },
                  }}
                  scroll={
                    tableHeight && {
                      x: isMobile ? "100vw" : "100vw",
                      y: tableHeight,
                      // x: "0px",
                    }
                  }
                  // dataSource={filteredDataTable || dataTable}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      {setSideContainer()}
    </div>
  ) : (
    <div
      style={{
        flex: "1",
        height: "100%",
        display: "flex",
        maxWidth: "100%",
        overflow: "hidden",
        flexDirection: "column",
      }}
    >
      {" "}
      <Spin spinning={loading}>
        <div
          className="generic-table-header-without-container"
          style={{ display: "flex", flexDirection: "row" }}
        >
          <span
            style={{
              marginRight: "auto",
              minHeight: "32px",
              display: "flex",
              alignItems: "center",
            }}
          >
            {subTabKey.charAt(0).toUpperCase() +
              subTabKey.split("_").join(" ").slice(1)}{" "}
            directory
            <InfoCircleOutlined
              style={{ color: "#00000073", paddingLeft: "2px" }}
            />
            {dataTable && dataTable.length > 0 ? (
              <>
                <span style={{ paddingLeft: "15px" }}>
                  <Button
                    style={{ width: "140px" }}
                    onClick={handleGenerateReportsClick}
                  >
                    <FileTextOutlined />
                    Generate report
                  </Button>
                </span>
              </>
            ) : null}
            {selectedRowKeys && selectedRowKeys.length > 0 ? (
              <>
                <span style={{ paddingLeft: "15px" }}>
                  <Button
                    style={{ width: "140px" }}
                    onClick={handleDeleteExcelClick}
                  >
                    <DeleteOutlined />
                    Delete
                  </Button>
                </span>
              </>
            ) : null}
          </span>
        </div>
        <div
          id="table-container"
          style={{
            flex: 1,
            display: "flex",
            maxHeight: "100%",
            maxWidth: "100%",
            overflow: "hidden",
          }}
        >
          {ready && (
            <Table
              rowClassName={(record: any) =>
                record?.uuid === activeRowKey ||
                `${record.group_uuid}-${record.tag_uuid}` === activeGroupKey ||
                (record.group_uuid === activeRowKey && !record.uuid)
                  ? "antd-table-row-active"
                  : ""
              }
              pagination={{
                defaultPageSize: 20,
                pageSizeOptions: [20, 50, 100],
                showSizeChanger: true,
                position: ["bottomCenter"],
              }}
              rowSelection={rowSelection}
              columns={tableColumns}
              scroll={
                tableHeight && {
                  x: isMobile ? "100vw" : "100vw",
                  y: tableHeight,
                }
              }
              dataSource={filteredDataTable || dataTable}
            />
          )}
        </div>
      </Spin>
      <Modal
        open={generate_reports_modal_visible}
        title={
          <div
            style={{
              padding: "15px",
              backgroundColor: "#2C378E",
              color: "white",
              minHeight: "25px",
              maxHeight: "25px",
            }}
          >
            Report generation
          </div>
        }
        okText="Generate"
        onCancel={handleGenerateReportsModalCancel}
        onOk={handleGenerateReportsModalCreate}
        closable={false}
        className="modal-report"
        footer={[
          <div style={{ padding: "16px" }}>
            <Button
              key="back"
              onClick={handleGenerateReportsModalCancel}
              style={{ marginRight: "10px" }}
            >
              Cancel
            </Button>

            <Button
              key="submit"
              type="primary"
              style={{
                backgroundColor: "#F7B01F",
                color: "white",
                borderWidth: " 1px, 0px, 1px, 1px",
                borderStyle: "solid",
                borderColor: "#F7B01F",
              }}
              onClick={handleGenerateReportsModalCreate}
            >
              Generate and download
            </Button>
          </div>,
        ]}
      >
        <CustomForm
          formRef={formRef}
          setFormReset={setFormReset}
          tabKey={"excelReportGenerate"}
          reportcompanyData={reportcompanyData}
          reportproductData={reportproductData}
        />
      </Modal>
      <Modal
        open={delete_excel_modal_visible}
        title={
          <div
            style={{
              padding: "15px",
              backgroundColor: "#2C378E",
              color: "white",
              minHeight: "25px",
              maxHeight: "25px",
            }}
          >
            Delete Excel
          </div>
        }
        okText="Generate"
        onCancel={handleDeleteExcelModalCancel}
        onOk={handleDeleteExcelConfirm}
        closable={false}
        className="modal-report"
        footer={[
          <div style={{ padding: "16px" }}>
            <Button
              key="back"
              onClick={handleDeleteExcelModalCancel}
              style={{ marginRight: "10px" }}
            >
              Cancel
            </Button>

            <Button
              key="submit"
              type="primary"
              style={{
                backgroundColor: "#F7B01F",
                color: "white",
                borderWidth: " 1px, 0px, 1px, 1px",
                borderStyle: "solid",
                borderColor: "#F7B01F",
              }}
              onClick={handleDeleteExcelConfirm}
            >
              Confirm Delete
            </Button>
          </div>,
        ]}
      >
        <p
          style={{
            display: "inline",
            marginRight: "10px",
            marginLeft: "15px",
          }}
        >
          {selectedRowKeys.length} file(s) has been selected.
        </p>
      </Modal>
    </div>
  );
  // </div>
};

export default LookupManager;
