import { useEffect, useState } from "react";
import {
  Form,
  Button,
  Divider,
  Popconfirm,
  Upload,
  Modal,
  message,
} from "antd";
import {
  DeleteOutlined,
  DownloadOutlined,
  InboxOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { v4 as uuidv4 } from "uuid";
import Emitter from "../../utils/emitter";

import { uploadFile, deleteFile } from "../../services/api-server/filetransfer";
import { getLookupItem } from "../../utils/lookup_list";
import {
  updateEmployeeItem,
  updateEmployeeItemResume,
} from "../../services/api-server/employee";

const EmployeeResume = (props: any) => {
  const formRef = props.formRef;
  const [resumeFormRef] = Form.useForm();
  const data = props.data["employees"] || {};
  const activeRowKey = props.activeRowKey;
  const [employeeData, setEmployeeData] = useState<any>(null);
  const [refreshResume, setRefreshResume] = useState<any>(0);

  const [resumeModalVisibility, setResumeModalVisibility] =
    useState<boolean>(false);

  const [fileNameUUIDList, setFileNameUUIDList] = useState<any>([]);

  const { Dragger } = Upload;

  const fetchData = () => {
    if (activeRowKey) {
      getLookupItem("employee").then((data: any) => {
        const employee = data.find((item: any) => item._id == activeRowKey);
        setEmployeeData(employee);
      });
    }
  };

  useEffect(() => {
    fetchData();
    // socket.on("refresh-employee-data", () => {
    //     fetchData();
    // });
  }, [refreshResume]);

  const addNewResume = () => {
    const handleCancel = () => {
      // nid to delete uploaded files
      if (fileNameUUIDList.length > 0) {
        fileNameUUIDList.map((item: any) => {
          deleteFile(item.uuid, "employee_files").then(() => {
            //console.log("k-debug file deleted", item);
          });
        });
      }
      setResumeModalVisibility(false);
    };

    const handleOk = () => {
      // function to add file details in employee resume
      if (fileNameUUIDList.length > 0) {
        // let resumeFormValues = {
        //     ...employeeData,
        //     ...resumeFormRef.getFieldsValue(),
        // };
        let resumeFormValues = resumeFormRef.getFieldsValue();

        if (resumeFormValues.resume !== undefined) {
          let resumeList = [];
          if (employeeData.resume) {
            employeeData.resume.map((item: any) => {
              resumeList.push(item);
            });
          }

          for (let i = 0; i < resumeFormValues.resume.fileList.length; i++) {
            const fileName = resumeFormValues.resume.fileList[i].name;
            const fileUUID = fileNameUUIDList.find(
              (item: any) => item.name === fileName
            )?.uuid;
            resumeList.push({
              name: fileName,
              uuid: fileUUID,
            });
            fileNameUUIDList.splice(
              fileNameUUIDList.findIndex((item: any) => item.name === fileName),
              1
            );
          }
          resumeFormValues.resume = resumeList;
          if (fileNameUUIDList.length > 0) {
            resumeFormValues.deleteResume = fileNameUUIDList;
          }
        }

        resumeFormValues.modified_on = Date.now();
        // delete resumeFormValues._id;

        updateEmployeeItemResume([activeRowKey], resumeFormValues)
          .then((data: any) => {
            // Emitter.emit("alert", {
            //     type: "success",
            //     title: "Success",
            //     message: "Employee resume saved.",
            //     description: "",
            //     top: true,
            //     closeable: false,
            //     timeout: 3000,
            // });
            message.success("Employee resume saved.");
            setEmployeeData(data);
          })
          .catch((error: any) => {
            //console.log("error", error);
            // Emitter.emit("alert", {
            //     type: "error",
            //     title: "Error",
            //     message: "Employee resume failed to saved.",
            //     description: "",
            //     top: true,
            //     closeable: false,
            //     timeout: 3000,
            // });
            message.error("Employee resume failed to saved.");
          })
          .finally(() => {
            setResumeModalVisibility(false);
            resumeFormRef.resetFields();
            setFileNameUUIDList([]);
            setRefreshResume(refreshResume + 1);
          });
      } else {
        Emitter.emit("alert", {
          type: "error",
          title: "Error",
          message: "No file uploaded.",
          description: "",
          top: true,
          closeable: false,
          timeout: 3000,
        });
      }
    };

    return (
      <Modal
        open={resumeModalVisibility}
        onCancel={handleCancel}
        onOk={handleOk}
        width={800}
        className="rejection-modal"
        footer={[
          <div
            style={{
              display: "flex",
              justifyContent: "right",
              // gap: "8px",
              // margin: "10px",
            }}
          >
            <div>
              <Button
                key="back"
                onClick={handleCancel}
                style={{
                  marginRight: "8px",
                  borderRadius: "3px",
                }} // Add margin-right for spacing
              >
                Cancel
              </Button>
              <Button
                key="submit"
                type="primary"
                onClick={handleOk}
                style={{ borderRadius: "3px" }}
              >
                Submit
              </Button>
            </div>
          </div>,
        ]}
      >
        <div style={{ marginTop: "20px", width: "100%" }}>
          {/* <p>Upload new resume</p> */}
          <Form
            layout="vertical"
            form={resumeFormRef}
            name="resume_upload_form"
            style={{
              gap: "10px",
              display: "flex",
              flexDirection: "column",
              width: "100%",
              height: "100%",
            }}
          >
            <Form.Item
              name="resume"
              label="Upload resume"
              // rules={[
              //     { required: true, message: "Please upload resume" },
              // ]}
              style={{
                width: "100%",
              }}
            >
              <Dragger
                name="resume"
                listType="picture"
                multiple={false}
                action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                customRequest={async (info: any) => {
                  const formData = new FormData();
                  formData.append("file", info.file);
                  const filename = info.file.name;

                  const imgUUID = uuidv4() + "." + filename.split(".").pop();
                  formData.append("uuid", imgUUID);
                  // formData.append(
                  //     "folder_name",
                  //     "employee_files"
                  // );

                  // uploadFile(formData)
                  //     .then((data: any) => {
                  //         setFileNameUUIDList([
                  //             ...fileNameUUIDList,
                  //             {
                  //                 name: filename,
                  //                 uuid: imgUUID,
                  //             },
                  //         ]);
                  //         info.onSuccess(data, info.file);
                  //     })
                  //     .catch((error: any) => {
                  //         info.onError(error, info.file);
                  //     });
                }}
                // onChange={(info) => {
                //     const { status } = info.file;
                //     if (status !== "uploading") {
                //      //console.log(info.file, info.fileList);
                //     }
                //     if (status === "done") {
                //         message.success(
                //             `${info.file.name} file uploaded successfully`
                //         );
                //     } else if (status === "error") {
                //         message.error(
                //             `${info.file.name} file upload failed.`
                //         );
                //     }
                // }}
                style={{
                  width: "100%",
                  borderColor: "#000000",
                }}
              >
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                  Click or drag file to this area to upload
                </p>
              </Dragger>
            </Form.Item>
          </Form>
        </div>
      </Modal>
    );
  };

  const handleDeleteResume = (item: any) => {
    // update employee resume
    updateEmployeeItem([activeRowKey], {
      resume: employeeData.resume.filter(
        (resume: any) => resume.uuid !== item.uuid
      ),
    }).then((data: any) => {
      deleteFile(item.uuid, "employee_files").then(() => {
        // Emitter.emit("alert", {
        //     type: "success",
        //     title: "Success",
        //     message: "Employee resume deleted.",
        //     description: "",
        //     top: true,
        //     closeable: false,
        //     timeout: 3000,
        // });
        message.success("Employee resume deleted.");
        setEmployeeData(data);
        setRefreshResume(refreshResume + 1);
      });
    });
  };

  return (
    <>
      <div className="generic-header" style={{ height: "50px" }}>
        Employee Resume
      </div>
      <div className="generic-content" style={{ flex: "1" }}>
        {/* <div style={{ flex: "1", position: "relative" }}></div> */}
        <Form
          form={formRef}
          layout="vertical"
          name="employee_resume"
          style={{
            width: "100%",
            padding: "10px",
            fontWeight: "bold",
          }}
        >
          <div
            style={{
              marginTop: "20px",
              width: "100%",
            }}
          >
            <Button
              onClick={() => {
                setResumeModalVisibility(true);
              }}
            >
              <UploadOutlined /> Upload new resume
            </Button>
          </div>
          {employeeData &&
            employeeData.resume &&
            employeeData.resume.length > 0 && (
              <>
                <Divider />
                <Form.Item
                  required={false}
                  label="Uploaded resume :"
                  name="resume"
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  {employeeData.resume.map((item: any, index: any) => {
                    return (
                      <div
                        key={index}
                        style={{
                          margin: "2px 5px",
                          display: "flex",
                          width: "50%",
                          justifyContent: "space-between",
                          border: "1px solid rgba(0, 0, 0, 0.2)",
                          borderRadius: "3px",
                        }}
                      >
                        {/* <a
                                                        href={`${item.resume_dir}`}
                                                        download
                                                    >
                                                        {item.resume_name}
                                                    </a> */}
                        <div
                          style={{
                            margin: "auto 10px",
                            fontWeight: "normal",
                          }}
                        >
                          {item.name}
                        </div>
                        <div>
                          <Button
                            type="link"
                            // onClick={() =>
                            //   downloadFile(
                            //     item.name,
                            //     item.uuid,
                            //     "employee_files"
                            //   )
                            // }
                          >
                            <DownloadOutlined />
                          </Button>
                          <Button type="link" danger>
                            <Popconfirm
                              placement="right"
                              title="Are you sure you want to delete this resume?"
                              onConfirm={() => handleDeleteResume(item)}
                              okText="Yes"
                              cancelText="No"
                            >
                              <DeleteOutlined />
                            </Popconfirm>
                          </Button>
                        </div>
                      </div>
                    );
                  })}
                </Form.Item>
              </>
            )}
        </Form>
      </div>
      {addNewResume()}
    </>
  );
};

export default EmployeeResume;
