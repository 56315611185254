import { v4 } from "uuid";
import SearchButton from "../components/SearchButton";
import { Select, Tag } from "antd";
import {
  addBusinessUnitItem,
  getBusinessUnitList,
  updateBusinessUnitItem,
} from "../services/api-server/default";
import { getEmployeeList } from "../services/api-server/employee";
import { getLeaveList } from "../services/api-server/leave";
import { getPositionList } from "../services/api-server/position";
import { getWorkdayList } from "../services/api-server/workdays";
import { getHolidayList } from "../services/api-server/publicholiday";
import { getLeaveTypeList } from "../services/api-server/leavetype";
import { getApprovalWorkflowList } from "../services/api-server/approvalworkflow";
import { getExcelList } from "../services/api-server/excel_file";
import { getLoanList, getLoanListByField } from "../services/api-server/loan";
// import { get } from "lodash";
import moment from "moment";
import { defaultColumn } from "react-table";
// import { getAllUsers } from "../services/api-server/user_acc";
import { useState } from "react";
import { getAllUsers } from "../services/api-server/user_acc";
import { render } from "@testing-library/react";
import { isMobile } from "react-device-detect";
import { getPolicyList } from "../services/api-server/policy";
import { getAnnouncementList } from "../services/api-server/announcement";
import { title } from "process";
import { Widgets } from "@mui/icons-material";
import { exec } from "child_process";

export const getColumnList = (
  lookupKey: any,
  handleSearch: any,
  option: any = null
) => {
  switch (lookupKey) {
    case "excel":
      return excelColumnKeys(lookupKey, handleSearch);
    case "employee":
      return employeeColumnKeys(lookupKey, handleSearch);
    case "leaveManager":
      switch (option) {
        case "rejected":
        case "cancelled":
          return leaveManagerRejectedColumnKeys(lookupKey, handleSearch);
        case "pending":
        case "approved":
          return leaveManagerDefaultColumnKeys(lookupKey, handleSearch);
        default:
          return leaveManagerColumnKeys(lookupKey, handleSearch);
      }
    case "application":
      switch (option) {
        default:
          return applicationColumnKeys(lookupKey, handleSearch);
      }
    case "userManager":
      return userManagerColumnKeys(lookupKey, handleSearch);
    case "administration":
      switch (option) {
        case "position":
          return administrationPositionColumnKeys(lookupKey, handleSearch);
        case "leavetype":
          return administrationLeavetypeColumnKeys(lookupKey, handleSearch);
        case "holiday":
          return administrationHolidayColumnKeys(lookupKey, handleSearch);
        case "workday":
          return administrationWorkdayColumnKeys(lookupKey, handleSearch);
        case "policy":
          return administrationPolicyColumnKeys(lookupKey, handleSearch);
        case "announcement":
          return announcementColumnKeys(lookupKey, handleSearch);
      }
      break;
    default:
      return null;
  }
};

export const getFormOptionList = (
  lookupKey: any,
  record: any = null,
  option: any = null
) => {
  switch (lookupKey) {
    case "default":
      return businessUnitFormList;
    case "project_tags":
      switch (option) {
        case "name":
          return projectTagsFormList;
        case "group":
          return projectTagGroupFormList;
      }
      break;
    case "employee":
      switch (option) {
        case "employee":
          return employeeFormList;
        case "intern":
          return internFormList;
      }
      break;

    case "leaveManager":
    case "application":
      switch (option) {
        case "pending":
        case "approved":
        case "all_leave":
          return leaveApprovalList;
        case "rejected":
          return leaveRejectionList;
        case "cancelled":
          return leaveCancellationList;
        case "configuration":
          return leaveConfigurationFormList;
      }
      break;
    case "userManager":
      return userRoleFormList;
    case "administration":
      switch (option) {
        case "position":
          return positionList;
        case "leavetype":
          return leavetypeList;
        case "holiday":
          return holidayList;
        case "workday":
          return workdayList;
        case "policy":
          return policyList;
        case "announcement":
          return announcementList;
      }
      break;
    case "excelReportGenerate":
      return [
        {
          label: "Title",
          name: "report_title",
          required: true,
          type: "input",
        },
        {
          label: "Type of Report",
          name: "report_type",
          required: true,
          type: "select-temporary",
          case: "report_type",
          default: "collection",
        },
        {
          label: "Month",
          name: "new_report_date",
          required: true,
          type: "datePickerMonth",
        },
        {
          label: "Format_month",
          name: "report_date",
          type: "hidden",
        },
        {
          label: "Company(s)",
          name: "company_name",
          required: true,
          type: "select-tree",
          case: "related_company",
          relay: "report_date",
        },
        {
          label: "Product(s)",
          name: "sub_acct_type",
          required: true,
          type: "select-temporary",
          case: "report_product",
          mode: "multiple",
          relay: "report_date",
        },
      ];
    default:
      return null;
  }
};

export const getLookupItem = (lookupKey: any) => {
  return new Promise((resolve, reject) => {
    try {
      switch (lookupKey) {
        // case "user_acc":
        //     getAllUsers()
        //         .then((value: any) => {
        //             resolve(value);
        //         })
        //         .catch((errorResponse: any) => {
        //             reject(errorResponse.response.data.error);
        //         });
        //     break;
        case "employee":
          getEmployeeList()
            .then((value: any) => {
              // console.log("TEST LOK MAK", value);
              resolve(value);
            })
            .catch((errorResponse: any) => {
              reject(errorResponse.response.data.error);
            });
          break;

        case "leave":
          getLeaveList()
            .then((leaveData: any) => {
              getLookupItem("employee")
                .then((employeeData: any) => {
                  // console.log("test im here", employeeData);
                  // console.log(
                  //     "test im here leave",
                  //     leaveData
                  // );
                  const leaveList = leaveData?.map((leave: any) => {
                    // Find the corresponding employee data using employee_id
                    const employee = employeeData?.find(
                      (employee: any) => employee._id === leave.employee_id
                    );
                    // Remove employee's _id && employee's status
                    const { _id, status, ...restEmployeeData } = employee;

                    // console.log(
                    //     "test",
                    //     leave,
                    //     restEmployeeData
                    // );

                    // Merge leave data with employee data
                    return {
                      ...leave,
                      ...restEmployeeData, // Merge employee data into leave data
                    };
                  });
                  // console.log("test leavelist", leaveList);
                  resolve(leaveList);
                })
                .catch((errorResponse: any) => {
                  reject(errorResponse.response.data.error);
                });
            })
            .catch((errorResponse: any) => {
              reject(errorResponse.response.data.error);
            });
          break;

        case "position":
          getPositionList()
            .then((value: any) => {
              resolve(value);
            })
            .catch((errorResponse: any) => {
              reject(errorResponse.response.data.error);
            });
          break;

        case "workday":
          getWorkdayList()
            .then((value: any) => {
              resolve(value);
            })
            .catch((errorResponse: any) => {
              reject(errorResponse.response.data.error);
            });
          break;

        case "holiday":
          getHolidayList()
            .then((value: any) => {
              resolve(value);
            })
            .catch((errorResponse: any) => {
              reject(errorResponse.response.data.error);
            });
          break;

        case "leavetype":
          getLeaveTypeList()
            .then((value: any) => {
              resolve(value);
            })
            .catch((errorResponse: any) => {
              reject(errorResponse.response.data.error);
            });
          break;

        case "useracc":
          getAllUsers()
            .then((value: any) => {
              resolve(value);
            })
            .catch((errorResponse: any) => {
              reject(errorResponse.response.data.error);
            });
          break;

        case "aprovalworkflow":
          getApprovalWorkflowList()
            .then((value: any) => {
              resolve(value);
            })
            .catch((errorResponse: any) => {
              reject(errorResponse.response.data.error);
            });
          break;

        case "policy":
          getPolicyList()
            .then((value: any) => {
              resolve(value);
            })
            .catch((errorResponse: any) => {
              reject(errorResponse.response.data.error);
            });
          break;

        case "announcement":
          getAnnouncementList()
            .then((value: any) => {
              resolve(value);
            })
            .catch((errorResponse: any) => {
              reject(errorResponse.response.data.error);
            });
          break;
        case "loan":
          getLoanList()
            .then((value: any) => {
              resolve(value);
            })
            .catch((errorResponse: any) => {
              reject(errorResponse.response.data.error);
            });
          break;
        case "excel":
          getExcelList()
            .then((value: any) => {
              resolve(value);
            })
            .catch((errorResponse: any) => {
              reject(errorResponse.response.data.error);
            });
          break;
        // case "report_company":
        //   let groupby = ["company_name", "branch_name", "report_date"];
        //   getLoanListByField(groupby)
        //     .then((value: any) => {
        //       resolve(value);
        //     })
        //     .catch((errorResponse: any) => {
        //       reject(errorResponse.response.data.error);
        //     });
        //   break;
        // case "generateExcelReport":
        //   // const param = {
        //   //   select: "title,status,asset,inspectionCategories,postedDate"
        //   // };
        //   getLoanListByField
      }
    } catch (error: any) {
      //console.log(error);
    }
  });
};

export const updateLookupItem = (
  lookupKey: any,
  formOption: any = null,
  uuid: any,
  data: any,
  initialData: any
) => {
  return new Promise((resolve, reject) => {
    try {
      data.modified_on = Date.now();
      switch (lookupKey) {
        case "strategic_business_unit":
          updateBusinessUnitItem(uuid, data)
            .then((value: any) => {
              resolve(value);
            })
            .catch((errorResponse: any) => {
              reject(errorResponse.response.data.error);
            });
          break;
      }
    } catch (error: any) {
      //console.log(error);
    }
  });
};

export const addLookupItem = (
  lookupKey: any,
  formOption: any = null,
  data: any
) => {
  return new Promise((resolve, reject) => {
    try {
      data.uuid = v4();
      data.created_on = Date.now();
      data.modified_on = Date.now();
      switch (lookupKey) {
        case "strategic_business_unit":
          addBusinessUnitItem(data)
            .then((value: any) => {
              resolve(value);
            })
            .catch((errorResponse: any) => {
              reject(errorResponse.response.data.error);
            });
          break;
      }
    } catch (error: any) {
      //console.log(error);
    }
  });
};

export const deleteLookupItem = (
  lookupKey: any,
  formOption: any = null,
  uuid: any
) => {
  return new Promise((resolve, reject) => {
    try {
      switch (lookupKey) {
      }
    } catch (error: any) {
      //console.log(error);
    }
  });
};

export const getAddButtonProp = (key: any) => {
  switch (key) {
    case "project_tags":
      return [
        { key: "name", label: "Tag" },
        { key: "group", label: "Tag group" },
      ];
    default:
      return null;
  }
};

export const multiCheckItems: any = (key: any) => {
  switch (key) {
    case "roles-permissions":
      return rolesPermissionList;
    default:
      return [];
  }
};

const employeeColumnKeys = (key: any, handleSearch: any) => {
  if (isMobile) {
    return [
      {
        title: (
          <div onClick={(e) => e.stopPropagation()}>
            <SearchButton
              handleSearch={handleSearch}
              headerName="EID"
              columnKey="staff_id_num"
              key={key + "id"}
            />
          </div>
        ),
        dataIndex: "staff_id_num",
        key: "staff_id_num",
        isActive: true,
        // width: "20%",
        showSorterTooltip: false,
        sorter: (a: any, b: any) => {
          return a["staff_id_num"]
            .toString()
            .localeCompare(b["staff_id_num"].toString());
        },
      },
      {
        title: (
          <div onClick={(e) => e.stopPropagation()}>
            <SearchButton
              handleSearch={handleSearch}
              headerName="Name"
              columnKey="fullname_nric"
              key={key + "fullname_nric"}
            />
          </div>
        ),
        dataIndex: "fullname_nric",
        key: "fullname_nric",
        // width: "20%",
        showSorterTooltip: false,
        sorter: (a: any, b: any) => {
          return a["fullname_nric"]
            .toString()
            .localeCompare(b["fullname_nric"].toString());
        },
      },
    ];
  } else {
    return [
      {
        title: (
          <div onClick={(e) => e.stopPropagation()}>
            <SearchButton
              handleSearch={handleSearch}
              headerName="Employee ID"
              columnKey="staff_id_num"
              key={key + "id"}
            />
          </div>
        ),
        dataIndex: "staff_id_num",
        key: "staff_id_num",
        width: "200px",
        isActive: true,
        showSorterTooltip: false,
        sorter: (a: any, b: any) => {
          return a["staff_id_num"]
            .toString()
            .localeCompare(b["staff_id_num"].toString());
        },
      },
      {
        title: (
          <div onClick={(e) => e.stopPropagation()}>
            <SearchButton
              handleSearch={handleSearch}
              headerName="Employee name"
              columnKey="fullname_nric"
              key={key + "fullname_nric"}
            />
          </div>
        ),
        dataIndex: "fullname_nric",
        key: "fullname_nric",
        width: "200px",
        showSorterTooltip: false,
        sorter: (a: any, b: any) => {
          return a["fullname_nric"]
            .toString()
            .localeCompare(b["fullname_nric"].toString());
        },
      },
      {
        title: (
          <div onClick={(e) => e.stopPropagation()}>
            <SearchButton
              handleSearch={handleSearch}
              headerName="NRIC/Passport number"
              columnKey="nric"
              key={key + "nric"}
            />
          </div>
        ),
        dataIndex: "nric",
        key: "nric",
        width: "250px",
        showSorterTooltip: false,
        sorter: (a: any, b: any) => {
          return a["nric"].toString().localeCompare(b["nric"].toString());
        },
      },
      {
        title: (
          <div onClick={(e) => e.stopPropagation()}>
            <SearchButton
              handleSearch={handleSearch}
              headerName="Position"
              columnKey="position"
              key={key + "position"}
            />
          </div>
        ),
        dataIndex: "position",
        key: "position",
        width: "200px",
        showSorterTooltip: false,
        sorter: (a: any, b: any) => {
          return a["position"]
            .toString()
            .localeCompare(b["position"].toString());
        },
      },
    ];
  }
};

const excelColumnKeys = (key: any, handleSearch: any) => {
  const formatDate = (dateString: any) => {
    const date = new Date(dateString);
    return moment(date).format("DD MMM YYYY");
  };
  const formatDateTime = (dateString: any) => {
    const date = new Date(dateString);
    return moment(date).format("DD MMM YYYY HH:mm:ss");
  };
  const formatSize = (string: any) => {
    let newString: any = 0;
    if (string < 1000) {
      return `${newString} B`;
    } else if (string >= 1000 && string < 1000000) {
      newString = (string / 1000).toFixed(0);
      return `${newString} KB`;
    } else {
      newString = (string / 1000000).toFixed(2);
      return `${newString} MB`;
    }
  };
  if (isMobile) {
    return [
      {
        title: (
          <div onClick={(e) => e.stopPropagation()}>
            <SearchButton
              handleSearch={handleSearch}
              headerName="Title"
              columnKey="filename"
              key={key + "filename"}
            />
          </div>
        ),
        dataIndex: "filename",
        key: "filename",
        isActive: true,
        // width: "20%",
        showSorterTooltip: false,
        sorter: (a: any, b: any) => {
          return a["filename"]
            .toString()
            .localeCompare(b["filename"].toString());
        },
      },
      {
        title: "Size",
        dataIndex: "size",
        key: "size",
        // width: "20%",
        showSorterTooltip: false,
        sorter: (a: any, b: any) => {
          return a["size"].toString().localeCompare(b["size"].toString());
        },
      },
    ];
  } else {
    return [
      {
        title: (
          <div onClick={(e) => e.stopPropagation()}>
            <SearchButton
              handleSearch={handleSearch}
              headerName="Title"
              columnKey="filename"
              key={key + "filename"}
            />
          </div>
        ),
        dataIndex: "filename",
        key: "filename",
        isActive: true,
        // width: "20%",
        showSorterTooltip: false,
        sorter: (a: any, b: any) => {
          return a["filename"]
            .toString()
            .localeCompare(b["filename"].toString());
        },
      },
      {
        title: "Size",
        dataIndex: "size",
        key: "size",
        // width: "20%",
        render: (item: any) => {
          return `${formatSize(item)}`;
        },
        showSorterTooltip: false,
        sorter: (a: any, b: any) => {
          return a["size"] - b["size"];
        },
      },
      {
        title: "Date uploaded",
        dataIndex: "upload_date",
        key: "upload_date",
        // width: "170px",
        render: (item: any) => {
          return `${formatDateTime(item)}`;
        },
        showSorterTooltip: false,
        sorter: (a: any, b: any) => {
          return (
            new Date(a.upload_date).getTime() -
            new Date(b.upload_date).getTime()
          );
        },
      },
      {
        title: "Report last generated",
        dataIndex: "report_generated_date",
        key: "report_generated_date",
        // width: "170px",
        render: (item: any) => {
          if (!item) {
            return "";
          }
          return `${formatDateTime(item)}`;
        },
        showSorterTooltip: false,
        sorter: (a: any, b: any) => {
          const dateA = a["report_generated_date"]
            ? new Date(a["report_generated_date"]).getTime()
            : 0;
          const dateB = b["report_generated_date"]
            ? new Date(b["report_generated_date"]).getTime()
            : 0;
          return dateA - dateB;
        },
      },
    ];
  }
};

const generateExcelReportList: any = [
  {
    label: "Title",
    name: "report_title",
    required: true,
    type: "input",
  },
  {
    label: "Type of Report",
    name: "report_type",
    required: true,
    type: "select-temporary",
    case: "report_type",
    default: "collection",
  },
  {
    label: "Month",
    name: "report_date",
    required: true,
    type: "datePickerMonth",
  },
  {
    label: "Format_month",
    name: "new_report_date",
    type: "hidden",
  },
  {
    label: "Company(s)",
    name: "company_name",
    required: true,
    type: "select-tree",
    case: "report_company",
    // default: ["all"],
  },
  {
    label: "Product(s)",
    name: "sub_acct_type",
    required: true,
    type: "select-temporary",
    case: "report_product",
    mode: "multiple",
    default: "default",
  },
];

const leaveManagerColumnKeys = (key: any, handleSearch: any) => {
  const formatDate = (dateString: any) => {
    const date = new Date(dateString);
    return moment(date).format("DD MMM YYYY");
  };
  const formatDateTime = (dateString: any) => {
    const date = new Date(dateString);
    return moment(date).format("DD MMM YYYY");
    // return moment(date).format("DD MMM YYYY HH:mm:ss");
  };
  if (isMobile) {
    return [
      {
        title: (
          <div onClick={(e) => e.stopPropagation()}>
            <SearchButton
              handleSearch={handleSearch}
              headerName="LID"
              columnKey="leave_id_num"
              key={key + "leave_id_num"}
            />
          </div>
        ),
        dataIndex: "leave_id_num",
        key: "leave_id_num",
        isActive: true,
        showSorterTooltip: false,
        // width: "150px",
        sorter: (a: any, b: any) => {
          return a["leave_id_num"]
            .toString()
            .localeCompare(b["leave_id_num"].toString());
        },
        // fixed: "left",
      },
      {
        title: (
          <div onClick={(e) => e.stopPropagation()}>
            <SearchButton
              handleSearch={handleSearch}
              headerName="Name"
              columnKey="fullname_nric"
              key={key + "fullname_nric"}
            />
          </div>
        ),
        dataIndex: "fullname_nric",
        key: "fullname_nric",
        showSorterTooltip: false,
        sorter: (a: any, b: any) => {
          return a["fullname_nric"]
            .toString()
            .localeCompare(b["fullname_nric"].toString());
        },
      },
      {
        title: "Status",
        // title: (
        //   <SearchButton
        //     handleSearch={handleSearch}
        //     headerName="Status"
        //     columnKey="status"
        //     key={key + "status"}
        //   />
        // ),
        dataIndex: "status",
        key: "status",
        render: (status: any) => {
          let tagColor, tagText;
          switch (status.toLowerCase()) {
            case "approved":
              tagColor = "success";
              break;
            case "rejected":
              tagColor = "error";
              break;
            case "pending":
              tagColor = "warning";
              break;
            default:
              tagColor = "default";
          }
          return <Tag color={tagColor}>{status}</Tag>;
        },
        filters: [
          { text: "approved", value: "approved" },
          { text: "rejected", value: "rejected" },
          { text: "pending", value: "pending" },
          { text: "cancelled", value: "cancelled" },
        ],
        onFilter: (value: boolean, record: any) => record.status === value,
        showSorterTooltip: false,
        sorter: (a: any, b: any) => {
          return a["status"].toString().localeCompare(b["status"].toString());
        },
      },
    ];
  } else {
    return [
      {
        title: (
          <div onClick={(e) => e.stopPropagation()}>
            <SearchButton
              handleSearch={handleSearch}
              headerName="LID"
              columnKey="leave_id_num"
              key={key + "leave_id_num"}
            />
          </div>
        ),
        dataIndex: "leave_id_num",
        key: "leave_id_num",
        isActive: true,
        width: "120px",
        showSorterTooltip: false,
        sorter: (a: any, b: any) => {
          return a["leave_id_num"]
            .toString()
            .localeCompare(b["leave_id_num"].toString());
        },
      },
      {
        title: (
          <div onClick={(e) => e.stopPropagation()}>
            <SearchButton
              handleSearch={handleSearch}
              headerName="Employee Name"
              columnKey="fullname_nric"
              key={key + "fullname_nric"}
            />
          </div>
        ),
        dataIndex: "fullname_nric",
        key: "fullname_nric",
        width: "200px",
        showSorterTooltip: false,
        sorter: (a: any, b: any) => {
          return a["fullname_nric"]
            .toString()
            .localeCompare(b["fullname_nric"].toString());
        },
      },
      {
        title: (
          <div onClick={(e) => e.stopPropagation()}>
            <SearchButton
              handleSearch={handleSearch}
              headerName="Leave Type"
              columnKey="leave_type"
              key={key + "leave_type"}
            />
          </div>
        ),
        dataIndex: "leave_type",
        key: "leave_type",
        width: "170px",
        render: (text: any) =>
          text.charAt(0).toUpperCase() + text.slice(1) + " Leave",
        showSorterTooltip: false,
        sorter: (a: any, b: any) => {
          return a["leave_type"]
            .toString()
            .localeCompare(b["leave_type"].toString());
        },
      },
      {
        title: (
          <div onClick={(e) => e.stopPropagation()}>
            <SearchButton
              handleSearch={handleSearch}
              headerName="Applied Date"
              columnKey="applied_date"
              key={key + "applied_date"}
            />
          </div>
        ),

        dataIndex: "applied_date",
        key: "applied_date",
        width: "170px",
        render: (item: any) => {
          return `${formatDateTime(item)}`;
        },
        showSorterTooltip: false,
        sorter: (a: any, b: any) => {
          return (
            new Date(a.applied_date).getTime() -
            new Date(b.applied_date).getTime()
          );
        },
      },
      {
        title: (
          <div onClick={(e) => e.stopPropagation()}>
            <SearchButton
              handleSearch={handleSearch}
              headerName="Leave Date(s)"
              columnKey="leave_date"
              key={key + "leave_date"}
            />
          </div>
        ),

        dataIndex: "leave_date",
        key: "leave_date",
        width: "200px",
        render: (_: any, record: any) => (
          <>
            {formatDate(record.start_date)} - {formatDate(record.end_date)}
          </>
        ),
        showSorterTooltip: false,
        sorter: (a: any, b: any) => {
          return (
            new Date(a.start_date).getTime() - new Date(b.start_date).getTime()
          );
        },
      },
      {
        title: (
          <div onClick={(e) => e.stopPropagation()}>
            <SearchButton
              handleSearch={handleSearch}
              headerName="Duration"
              columnKey="duration"
              key={key + "duration"}
            />
          </div>
        ),
        dataIndex: "duration",
        key: "duration",
        width: "150px",
        render: (item: any) => {
          return `${item}`;
        },
        showSorterTooltip: false,
        sorter: (a: any, b: any) => {
          return a["duration"]
            .toString()
            .localeCompare(b["duration"].toString());
        },
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        width: "150px",
        render: (status: any) => {
          let tagColor, tagText;
          switch (status.toLowerCase()) {
            case "approved":
              tagColor = "success";
              break;
            case "rejected":
              tagColor = "error";
              break;
            case "pending":
              tagColor = "warning";
              break;
            default:
              tagColor = "default";
          }
          return <Tag color={tagColor}>{status}</Tag>;
        },
        filters: [
          { text: "approved", value: "approved" },
          { text: "rejected", value: "rejected" },
          { text: "pending", value: "pending" },
          { text: "cancelled", value: "cancelled" },
        ],
        onFilter: (value: boolean, record: any) => record.status === value,
        showSorterTooltip: false,
        sorter: (a: any, b: any) => {
          return a["status"].toString().localeCompare(b["status"].toString());
        },
      },
    ];
  }
};

const leaveManagerDefaultColumnKeys = (key: any, handleSearch: any) => {
  const formatDate = (dateString: any) => {
    const date = new Date(dateString);
    return moment(date).format("DD MMM YYYY");
    // return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
  };
  const formatDateTime = (dateString: any) => {
    const date = new Date(dateString);
    return moment(date).format("DD MMM YYYY");
    // return moment(date).format("DD MMM YYYY HH:mm:ss");
  };

  if (isMobile) {
    return [
      {
        title: (
          <div onClick={(e) => e.stopPropagation()}>
            <SearchButton
              handleSearch={handleSearch}
              headerName="LID"
              columnKey="leave_id_num"
              key={key + "leave_id_num"}
            />
          </div>
        ),
        dataIndex: "leave_id_num",
        key: "leave_id_num",
        isActive: true,
        showSorterTooltip: false,
        sorter: (a: any, b: any) => {
          return a["leave_id_num"]
            .toString()
            .localeCompare(b["leave_id_num"].toString());
        },
      },
      {
        title: (
          <div onClick={(e) => e.stopPropagation()}>
            <SearchButton
              handleSearch={handleSearch}
              headerName="Name"
              columnKey="fullname_nric"
              key={key + "fullname_nric"}
            />
          </div>
        ),
        dataIndex: "fullname_nric",
        key: "fullname_nric",
        showSorterTooltip: false,
        sorter: (a: any, b: any) => {
          return a["fullname_nric"]
            .toString()
            .localeCompare(b["fullname_nric"].toString());
        },
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        render: (status: any) => {
          let tagColor, tagText;
          switch (status.toLowerCase()) {
            case "approved":
              tagColor = "success";
              break;
            case "rejected":
              tagColor = "error";
              break;
            case "pending":
              tagColor = "warning";
              break;
            default:
              tagColor = "default";
          }
          return <Tag color={tagColor}>{status}</Tag>;
        },
        showSorterTooltip: false,
        sorter: (a: any, b: any) => {
          return a["status"].toString().localeCompare(b["status"].toString());
        },
      },
    ];
  } else {
    return [
      {
        title: (
          <div onClick={(e) => e.stopPropagation()}>
            <SearchButton
              handleSearch={handleSearch}
              headerName="LID"
              columnKey="leave_id_num"
              key={key + "leave_id_num"}
            />
          </div>
        ),
        dataIndex: "leave_id_num",
        key: "leave_id_num",
        isActive: true,
        width: "120px",
        showSorterTooltip: false,
        sorter: (a: any, b: any) => {
          return a["leave_id_num"]
            .toString()
            .localeCompare(b["leave_id_num"].toString());
        },
      },
      {
        title: (
          <div onClick={(e) => e.stopPropagation()}>
            <SearchButton
              handleSearch={handleSearch}
              headerName="Employee name"
              columnKey="fullname_nric"
              key={key + "fullname_nric"}
            />
          </div>
        ),
        dataIndex: "fullname_nric",
        key: "fullname_nric",
        width: "200px",
        showSorterTooltip: false,
        sorter: (a: any, b: any) => {
          return a["fullname_nric"]
            .toString()
            .localeCompare(b["fullname_nric"].toString());
        },
      },
      {
        title: (
          <div onClick={(e) => e.stopPropagation()}>
            <SearchButton
              handleSearch={handleSearch}
              headerName="Leave Type"
              columnKey="leave_type"
              key={key + "leave_type"}
            />
          </div>
        ),
        dataIndex: "leave_type",
        key: "leave_type",
        width: "170px",
        render: (text: any) =>
          text.charAt(0).toUpperCase() + text.slice(1) + " Leave",
        showSorterTooltip: false,
        sorter: (a: any, b: any) => {
          return a["leave_type"]
            .toString()
            .localeCompare(b["leave_type"].toString());
        },
      },
      {
        title: (
          <div onClick={(e) => e.stopPropagation()}>
            <SearchButton
              handleSearch={handleSearch}
              headerName="Applied Date"
              columnKey="applied_date"
              key={key + "applied_date"}
            />
          </div>
        ),

        dataIndex: "applied_date",
        key: "applied_date",
        width: "170px",
        render: (item: any) => {
          return `${formatDateTime(item)}`;
        },
        showSorterTooltip: false,
        sorter: (a: any, b: any) => {
          return (
            new Date(a.applied_date).getTime() -
            new Date(b.applied_date).getTime()
          );
        },
      },
      {
        title: (
          <div onClick={(e) => e.stopPropagation()}>
            <SearchButton
              handleSearch={handleSearch}
              headerName="Leave Date(s)"
              columnKey="leave_date"
              key={key + "leave_date"}
            />
          </div>
        ),
        dataIndex: "leave_date",
        key: "leave_date",
        width: "200px",
        render: (_: any, record: any) => (
          <>
            {formatDate(record.start_date)} - {formatDate(record.end_date)}
          </>
        ),
        showSorterTooltip: false,
        sorter: (a: any, b: any) => {
          return (
            new Date(a.start_date).getTime() - new Date(b.start_date).getTime()
          );
        },
      },
      {
        title: (
          <div onClick={(e) => e.stopPropagation()}>
            <SearchButton
              handleSearch={handleSearch}
              headerName="Duration"
              columnKey="duration"
              key={key + "duration"}
            />
          </div>
        ),
        dataIndex: "duration",
        key: "duration",
        width: "150px",
        render: (item: any) => {
          return `${item}`;
        },
        showSorterTooltip: false,
        sorter: (a: any, b: any) => {
          return a["duration"]
            .toString()
            .localeCompare(b["duration"].toString());
        },
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        width: "150px",
        render: (status: any) => {
          let tagColor, tagText;
          switch (status.toLowerCase()) {
            case "approved":
              tagColor = "success";
              break;
            case "rejected":
              tagColor = "error";
              break;
            case "pending":
              tagColor = "warning";
              break;
            default:
              tagColor = "default";
          }
          return <Tag color={tagColor}>{status}</Tag>;
        },
        showSorterTooltip: false,
        // sorter: (a: any, b: any) => {
        //   return a["status"].toString().localeCompare(b["status"].toString());
        // },
      },
    ];
  }
};

const leaveManagerRejectedColumnKeys = (key: any, handleSearch: any) => {
  const formatDate = (dateString: any) => {
    const date = new Date(dateString);
    return moment(date).format("DD MMM YYYY");
    // return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
  };
  const formatDateTime = (dateString: any) => {
    const date = new Date(dateString);
    return moment(date).format("DD MMM YYYY");
    // return moment(date).format("DD MMM YYYY HH:mm:ss");
  };

  if (isMobile) {
    return [
      {
        title: (
          <div onClick={(e) => e.stopPropagation()}>
            <SearchButton
              handleSearch={handleSearch}
              headerName="LID"
              columnKey="leave_id_num"
              key={key + "leave_id_num"}
            />
          </div>
        ),
        dataIndex: "leave_id_num",
        key: "leave_id_num",
        isActive: true,
        showSorterTooltip: false,
        sorter: (a: any, b: any) => {
          return a["leave_id_num"]
            .toString()
            .localeCompare(b["leave_id_num"].toString());
        },
      },
      {
        title: (
          <div onClick={(e) => e.stopPropagation()}>
            <SearchButton
              handleSearch={handleSearch}
              headerName="Name"
              columnKey="fullname_nric"
              key={key + "fullname_nric"}
            />
          </div>
        ),
        dataIndex: "fullname_nric",
        key: "fullname_nric",
        showSorterTooltip: false,
        sorter: (a: any, b: any) => {
          return a["fullname_nric"]
            .toString()
            .localeCompare(b["fullname_nric"].toString());
        },
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        render: (status: any) => {
          let tagColor, tagText;
          switch (status.toLowerCase()) {
            case "approved":
              tagColor = "success";
              break;
            case "rejected":
              tagColor = "error";
              break;
            case "pending":
              tagColor = "warning";
              break;
            default:
              tagColor = "default";
          }
          return <Tag color={tagColor}>{status}</Tag>;
        },
        showSorterTooltip: false,
      },
    ];
  } else {
    return [
      {
        title: (
          <div onClick={(e) => e.stopPropagation()}>
            <SearchButton
              handleSearch={handleSearch}
              headerName="LID"
              columnKey="leave_id_num"
              key={key + "leave_id_num"}
            />
          </div>
        ),
        dataIndex: "leave_id_num",
        key: "leave_id_num",
        isActive: true,
        width: "120px",
        showSorterTooltip: false,
        sorter: (a: any, b: any) => {
          return a["leave_id_num"]
            .toString()
            .localeCompare(b["leave_id_num"].toString());
        },
      },
      {
        title: (
          <div onClick={(e) => e.stopPropagation()}>
            <SearchButton
              handleSearch={handleSearch}
              headerName="Employee name"
              columnKey="fullname_nric"
              key={key + "fullname_nric"}
            />
          </div>
        ),
        dataIndex: "fullname_nric",
        key: "fullname_nric",
        width: "200px",
        showSorterTooltip: false,
        sorter: (a: any, b: any) => {
          return a["fullname_nric"]
            .toString()
            .localeCompare(b["fullname_nric"].toString());
        },
      },
      {
        title: (
          <div onClick={(e) => e.stopPropagation()}>
            <SearchButton
              handleSearch={handleSearch}
              headerName="Leave Type"
              columnKey="leave_type"
              key={key + "leave_type"}
            />
          </div>
        ),
        dataIndex: "leave_type",
        key: "leave_type",
        width: "170px",
        render: (text: any) =>
          text.charAt(0).toUpperCase() + text.slice(1) + " Leave",
        showSorterTooltip: false,
        sorter: (a: any, b: any) => {
          return a["leave_type"]
            .toString()
            .localeCompare(b["leave_type"].toString());
        },
      },
      {
        title: (
          <div onClick={(e) => e.stopPropagation()}>
            <SearchButton
              handleSearch={handleSearch}
              headerName="Applied Date"
              columnKey="applied_date"
              key={key + "applied_date"}
            />
          </div>
        ),

        dataIndex: "applied_date",
        key: "applied_date",
        width: "170px",
        render: (item: any) => {
          return `${formatDateTime(item)}`;
        },
        showSorterTooltip: false,
        sorter: (a: any, b: any) => {
          return (
            new Date(a.applied_date).getTime() -
            new Date(b.applied_date).getTime()
          );
        },
      },
      {
        title: (
          <div onClick={(e) => e.stopPropagation()}>
            <SearchButton
              handleSearch={handleSearch}
              headerName="Leave Date(s)"
              columnKey="leave_date"
              key={key + "leave_date"}
            />
          </div>
        ),
        dataIndex: "leave_date",
        key: "leave_date",
        width: "200px",
        render: (_: any, record: any) => (
          <>
            {formatDate(record.start_date)} - {formatDate(record.end_date)}
          </>
        ),
        showSorterTooltip: false,
        sorter: (a: any, b: any) => {
          return (
            new Date(a.start_date).getTime() - new Date(b.start_date).getTime()
          );
        },
      },
      {
        title: (
          <div onClick={(e) => e.stopPropagation()}>
            <SearchButton
              handleSearch={handleSearch}
              headerName="Duration"
              columnKey="duration"
              key={key + "duration"}
            />
          </div>
        ),
        dataIndex: "duration",
        key: "duration",
        width: "150px",
        render: (item: any) => {
          return `${item}`;
        },
        showSorterTooltip: false,
        sorter: (a: any, b: any) => {
          return a["duration"]
            .toString()
            .localeCompare(b["duration"].toString());
        },
      },
      {
        title: (
          <div onClick={(e) => e.stopPropagation()}>
            <SearchButton
              handleSearch={handleSearch}
              headerName="Reason"
              columnKey="denial_reason"
              key={key + "denial_reason"}
            />
          </div>
        ),
        dataIndex: "denial_reason",
        key: "denial_reason",
        width: "170px",
        showSorterTooltip: false,
        sorter: (a: any, b: any) => {
          const aDenialReason = a["denial_reason"]
            ? a["denial_reason"].toString()
            : "";
          const bDenialReason = b["denial_reason"]
            ? b["denial_reason"].toString()
            : "";
          return aDenialReason.localeCompare(bDenialReason);
        },
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        width: "150px",
        render: (status: any) => {
          let tagColor, tagText;
          switch (status.toLowerCase()) {
            case "approved":
              tagColor = "success";
              break;
            case "rejected":
              tagColor = "error";
              break;
            case "pending":
              tagColor = "warning";
              break;
            default:
              tagColor = "default";
          }
          return <Tag color={tagColor}>{status}</Tag>;
        },
        showSorterTooltip: false,
      },
    ];
  }
};

const applicationColumnKeys = (key: any, handleSearch: any) => {
  const formatDate = (dateString: any) => {
    const date = new Date(dateString);
    return moment(date).format("DD MMM YYYY");
    // return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
  };
  const formatDateTime = (dateString: any) => {
    const date = new Date(dateString);
    return moment(date).format("DD MMM YYYY");
    // return moment(date).format("DD MMM YYYY HH:mm:ss");
  };

  if (isMobile) {
    return [
      {
        title: (
          <div onClick={(e) => e.stopPropagation()}>
            <SearchButton
              handleSearch={handleSearch}
              headerName="LID"
              columnKey="leave_id_num"
              key={key + "leave_id_num"}
            />
          </div>
        ),
        dataIndex: "leave_id_num",
        key: "leave_id_num",
        isActive: true,
        showSorterTooltip: false,
        sorter: (a: any, b: any) => {
          return a["leave_id_num"]
            .toString()
            .localeCompare(b["leave_id_num"].toString());
        },
      },
      {
        title: (
          <div onClick={(e) => e.stopPropagation()}>
            <SearchButton
              handleSearch={handleSearch}
              headerName="Name"
              columnKey="fullname_nric"
              key={key + "fullname_nric"}
            />
          </div>
        ),
        dataIndex: "fullname_nric",
        key: "fullname_nric",
        showSorterTooltip: false,
        sorter: (a: any, b: any) => {
          return a["fullname_nric"]
            .toString()
            .localeCompare(b["fullname_nric"].toString());
        },
      },
      {
        title: "Status",
        // title: (
        //   <SearchButton
        //     handleSearch={handleSearch}
        //     headerName="Status"
        //     columnKey="status"
        //     key={key + "status"}
        //   />
        // ),
        dataIndex: "status",
        key: "status",
        render: (status: any) => {
          let tagColor, tagText;
          switch (status.toLowerCase()) {
            case "approved":
              tagColor = "success";
              break;
            case "rejected":
              tagColor = "error";
              break;
            case "pending":
              tagColor = "warning";
              break;
            default:
              tagColor = "default";
          }
          return <Tag color={tagColor}>{status}</Tag>;
        },
        filters: [
          { text: "approved", value: "approved" },
          { text: "rejected", value: "rejected" },
          { text: "pending", value: "pending" },
          { text: "cancelled", value: "cancelled" },
        ],
        onFilter: (value: boolean, record: any) => record.status === value,
        showSorterTooltip: false,
        sorter: (a: any, b: any) => {
          return a["status"].toString().localeCompare(b["status"].toString());
        },
      },
    ];
  } else {
  }
  return [
    {
      title: (
        <div onClick={(e) => e.stopPropagation()}>
          <SearchButton
            handleSearch={handleSearch}
            headerName="LID"
            columnKey="leave_id_num"
            key={key + "leave_id_num"}
          />
        </div>
      ),
      dataIndex: "leave_id_num",
      key: "leave_id_num",
      isActive: true,
      width: "120px",
      showSorterTooltip: false,
      sorter: (a: any, b: any) => {
        return a["leave_id_num"]
          .toString()
          .localeCompare(b["leave_id_num"].toString());
      },
    },
    {
      title: (
        <div onClick={(e) => e.stopPropagation()}>
          <SearchButton
            handleSearch={handleSearch}
            headerName="Employee name"
            columnKey="fullname_nric"
            key={key + "fullname_nric"}
          />
        </div>
      ),
      dataIndex: "fullname_nric",
      key: "fullname_nric",
      width: "200px",
      showSorterTooltip: false,
      sorter: (a: any, b: any) => {
        return a["fullname_nric"]
          .toString()
          .localeCompare(b["fullname_nric"].toString());
      },
    },
    {
      title: (
        <div onClick={(e) => e.stopPropagation()}>
          <SearchButton
            handleSearch={handleSearch}
            headerName="Leave Type"
            columnKey="leave_type"
            key={key + "leave_type"}
          />
        </div>
      ),
      dataIndex: "leave_type",
      key: "leave_type",
      width: "170px",
      render: (text: any) =>
        text.charAt(0).toUpperCase() + text.slice(1) + " Leave",
      showSorterTooltip: false,
      sorter: (a: any, b: any) => {
        return a["leave_type"]
          .toString()
          .localeCompare(b["leave_type"].toString());
      },
    },
    {
      title: (
        <div onClick={(e) => e.stopPropagation()}>
          <SearchButton
            handleSearch={handleSearch}
            headerName="Applied Date"
            columnKey="applied_date"
            key={key + "applied_date"}
          />
        </div>
      ),

      dataIndex: "applied_date",
      key: "applied_date",
      width: "170px",
      render: (item: any) => {
        return `${formatDateTime(item)}`;
      },
      showSorterTooltip: false,
      sorter: (a: any, b: any) => {
        return (
          new Date(a.applied_date).getTime() -
          new Date(b.applied_date).getTime()
        );
      },
    },
    {
      title: (
        <div onClick={(e) => e.stopPropagation()}>
          <SearchButton
            handleSearch={handleSearch}
            headerName="Leave Date(s)"
            columnKey="leave_date"
            key={key + "leave_date"}
          />
        </div>
      ),
      dataIndex: "leave_date",
      key: "leave_date",
      width: "200px",
      render: (_: any, record: any) => (
        <>
          {formatDate(record.start_date)} - {formatDate(record.end_date)}
        </>
      ),
      showSorterTooltip: false,
      sorter: (a: any, b: any) => {
        return (
          new Date(a.start_date).getTime() - new Date(b.start_date).getTime()
        );
      },
    },
    {
      title: (
        <div onClick={(e) => e.stopPropagation()}>
          <SearchButton
            handleSearch={handleSearch}
            headerName="Duration"
            columnKey="duration"
            key={key + "duration"}
          />
        </div>
      ),
      dataIndex: "duration",
      key: "duration",
      width: "150px",
      render: (item: any) => {
        return `${item}`;
      },
      showSorterTooltip: false,
      sorter: (a: any, b: any) => {
        return a["duration"].toString().localeCompare(b["duration"].toString());
      },
    },
    {
      title: "Status",
      // title: (
      //   <SearchButton
      //     handleSearch={handleSearch}
      //     headerName="Status"
      //     columnKey="status"
      //     key={key + "status"}
      //   />
      // ),
      dataIndex: "status",
      key: "status",
      width: "150px",
      render: (status: any) => {
        let tagColor, tagText;
        switch (status.toLowerCase()) {
          case "approved":
            tagColor = "success";
            break;
          case "rejected":
            tagColor = "error";
            break;
          case "pending":
            tagColor = "warning";
            break;
          default:
            tagColor = "default";
        }
        return <Tag color={tagColor}>{status}</Tag>;
      },
      filters: [
        { text: "approved", value: "approved" },
        { text: "rejected", value: "rejected" },
        { text: "pending", value: "pending" },
        { text: "cancelled", value: "cancelled" },
      ],
      onFilter: (value: boolean, record: any) => record.status === value,
      showSorterTooltip: false,
      sorter: (a: any, b: any) => {
        return a["status"].toString().localeCompare(b["status"].toString());
      },
    },
    {
      title: (
        <div onClick={(e) => e.stopPropagation()}>
          <SearchButton
            handleSearch={handleSearch}
            headerName="Reason (if any)"
            columnKey="denial_reason"
            key={key + "denial_reason"}
          />
        </div>
      ),
      dataIndex: "denial_reason",
      key: "denial_reason",
      width: "170px",
      showSorterTooltip: false,
      sorter: (a: any, b: any) => {
        const aDenialReason = a["denial_reason"]
          ? a["denial_reason"].toString()
          : "";
        const bDenialReason = b["denial_reason"]
          ? b["denial_reason"].toString()
          : "";
        return aDenialReason.localeCompare(bDenialReason);
      },
    },
  ];
};

const userManagerColumnKeys = (key: any, handleSearch: any) => {
  let employee_data: any = {};

  Promise.all([getLookupItem("employee")]).then((data: any) => {
    employee_data = data[0].reduce((acc: any, item: any) => {
      acc[item._id] = `${item.staff_id_num} - ${item.fullname_nric}`;
      return acc;
    }, {});
  });

  return [
    {
      title: (
        <div onClick={(e) => e.stopPropagation()}>
          <SearchButton
            handleSearch={handleSearch}
            headerName="Email"
            columnKey="email"
            key={key + "email"}
          />
        </div>
      ),
      dataIndex: "email",
      key: "email",
      isActive: true,
      showSorterTooltip: false,
      sorter: (a: any, b: any) => {
        return a["email"].toString().localeCompare(b["email"].toString());
      },
    },
    {
      title: (
        <div onClick={(e) => e.stopPropagation()}>
          <SearchButton
            handleSearch={handleSearch}
            headerName="Role"
            columnKey="roles"
            key={key + "roles"}
          />
        </div>
      ),
      dataIndex: "roles",
      key: "roles",
      render: (role: any) => {
        let tagColor, tagText;
        switch (role[0].toLowerCase()) {
          case "admin":
            tagColor = "success";
            break;
          case "user":
            tagColor = "default";
            break;
          case "pending":
            tagColor = "warning";
            break;
          default:
            tagColor = "default";
        }
        return <Tag color={tagColor}>{role}</Tag>;
      },
      showSorterTooltip: false,
      sorter: (a: any, b: any) => {
        return a["roles"].toString().localeCompare(b["roles"].toString());
      },
    },
    {
      // title: "Employee ID",
      title: (
        <div onClick={(e) => e.stopPropagation()}>
          <SearchButton
            handleSearch={handleSearch}
            headerName="Employee ID"
            columnKey="employee_id"
            key={key + "employee_id"}
          />
        </div>
      ),
      dataIndex: "employee_id",
      key: "employee_id",
      render: (staffId: any) => {
        // Check if staffId is empty
        if (!staffId) {
          return "Not assigned";
        }
        return `${employee_data[staffId]}`;
      },
      showSorterTooltip: false,
      sorter: (a: any, b: any) => {
        const aEmployeeId = a["employee_id"] ? a["employee_id"].toString() : "";
        const bEmployeeId = b["employee_id"] ? b["employee_id"].toString() : "";
        return aEmployeeId.localeCompare(bEmployeeId);
      },
    },
  ];
};

const administrationPositionColumnKeys = (key: any, handleSearch: any) => {
  return [
    {
      title: (
        <div onClick={(e) => e.stopPropagation()}>
          <SearchButton
            handleSearch={handleSearch}
            headerName={isMobile ? "Code" : "Position code"}
            columnKey="position_code"
            key={key + "position_code"}
          />
        </div>
      ),
      dataIndex: "position_code",
      key: "position_code",
      width: "200px",
      isActive: true,
      showSorterTooltip: false,
      sorter: (a: any, b: any) => {
        return a["position_code"]
          .toString()
          .localeCompare(b["position_code"].toString());
      },
    },
    {
      title: (
        <div onClick={(e) => e.stopPropagation()}>
          <SearchButton
            handleSearch={handleSearch}
            headerName={isMobile ? "Name" : "Position name"}
            columnKey="position_name"
            key={key + "position_name"}
          />
        </div>
      ),
      dataIndex: "position_name",
      key: "position_name",
      width: "250px",
      showSorterTooltip: false,
      sorter: (a: any, b: any) => {
        return a["position_name"]
          .toString()
          .localeCompare(b["position_name"].toString());
      },
    },
  ];
};

const administrationLeavetypeColumnKeys = (key: any, handleSearch: any) => {
  return [
    {
      title: (
        <div onClick={(e) => e.stopPropagation()}>
          <SearchButton
            handleSearch={handleSearch}
            headerName={isMobile ? "Code" : "Leave type code"}
            columnKey="leave_type_code"
            key={key + "leave_type_code"}
          />
        </div>
      ),
      dataIndex: "leave_type_code",
      key: "leave_type_code",
      width: "250px",
      isActive: true,
      showSorterTooltip: false,
      sorter: (a: any, b: any) => {
        return a["leave_type_code"]
          .toString()
          .localeCompare(b["leave_type_code"].toString());
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: "150px",
      render: (status: any) => {
        let tagColor, tagText;
        switch (status) {
          case true:
            tagColor = "success";
            break;
          case false:
            tagColor = "error";
            break;
          default:
            tagColor = "default";
        }
        return <Tag color={tagColor}>{status ? "Active" : "Inactive"}</Tag>;
      },
      filters: [
        { text: "Active", value: true },
        { text: "Inactive", value: false },
      ],
      onFilter: (value: boolean, record: any) => record.status === value,
      showSorterTooltip: false,
      sorter: (a: any, b: any) => {
        return a["status"].toString().localeCompare(b["status"].toString());
      },
    },
    {
      title: (
        <div onClick={(e) => e.stopPropagation()}>
          <SearchButton
            handleSearch={handleSearch}
            headerName={isMobile ? "Name" : "Leave type name"}
            columnKey="leave_type_name"
            key={key + "leave_type_name"}
          />
        </div>
      ),
      dataIndex: "leave_type_name",
      key: "leave_type_name",
      width: "250px",
      showSorterTooltip: false,
      sorter: (a: any, b: any) => {
        return a["leave_type_name"]
          .toString()
          .localeCompare(b["leave_type_name"].toString());
      },
    },
  ];
};

const administrationHolidayColumnKeys = (key: any, handleSearch: any) => {
  const formatDate = (dateString: any) => {
    const date = new Date(dateString);
    return moment(date).format("DD MMM YYYY");
  };
  const formatDateTime = (dateString: any) => {
    const date = new Date(dateString);
    return moment(date).format("DD MMM YYYY HH:mm:ss");
  };
  return [
    {
      title: (
        <div onClick={(e) => e.stopPropagation()}>
          <SearchButton
            handleSearch={handleSearch}
            headerName={isMobile ? "Holiday" : "Holiday name"}
            columnKey="holiday_name"
            key={key + "holiday_name"}
          />
        </div>
      ),
      dataIndex: "holiday_name",
      key: "holiday_name",
      width: "200px",
      isActive: true,
      showSorterTooltip: false,
      sorter: (a: any, b: any) => {
        return a["holiday_name"]
          .toString()
          .localeCompare(b["holiday_name"].toString());
      },
    },
    {
      title: (
        <div onClick={(e) => e.stopPropagation()}>
          <SearchButton
            handleSearch={handleSearch}
            headerName={isMobile ? "Date" : "Holiday date"}
            columnKey="ph_date"
            key={key + "ph_date"}
          />
        </div>
      ),
      dataIndex: "ph_date",
      key: "ph_date",
      width: "200px",
      render: (record: any) => <>{formatDate(record)}</>,
      showSorterTooltip: false,
      sorter: (a: any, b: any) => {
        return new Date(a.ph_date).getTime() - new Date(b.ph_date).getTime();
      },
    },
  ];
};

const administrationWorkdayColumnKeys = (key: any, handleSearch: any) => {
  // Function to capitalize the first letter of leaves
  const formatWorkdays = (str: string) => {
    if (str === "offday") {
      return "Off day";
    } else {
      return "Full day";
    }
  };

  if (isMobile) {
    return [
      {
        title: (
          <div onClick={(e) => e.stopPropagation()}>
            <SearchButton
              handleSearch={handleSearch}
              headerName="Weekday"
              columnKey="weekday"
              key={key + "weekday"}
            />
          </div>
        ),
        dataIndex: "weekday",
        key: "weekday",
        isActive: true,
        showSorterTooltip: false,
        sorter: (a: any, b: any) => {
          return a["weekday"].toString().localeCompare(b["weekday"].toString());
        },
      },
      {
        title: (
          <div onClick={(e) => e.stopPropagation()}>
            <SearchButton
              handleSearch={handleSearch}
              headerName="Work Period"
              columnKey="workperiod"
              key={key + "workperiod"}
            />
          </div>
        ),
        dataIndex: "workperiod",
        key: "workperiod",
        render: (record: any) => <>{formatWorkdays(record)}</>,
        showSorterTooltip: false,
        sorter: (a: any, b: any) => {
          return a["workperiod"]
            .toString()
            .localeCompare(b["workperiod"].toString());
        },
      },
    ];
  } else {
    return [
      {
        title: (
          <div onClick={(e) => e.stopPropagation()}>
            <SearchButton
              handleSearch={handleSearch}
              headerName="Weekday"
              columnKey="weekday"
              key={key + "weekday"}
            />
          </div>
        ),
        dataIndex: "weekday",
        key: "weekday",
        width: "150px",
        isActive: true,
        showSorterTooltip: false,
        sorter: (a: any, b: any) => {
          return a["weekday"].toString().localeCompare(b["weekday"].toString());
        },
      },
      {
        title: (
          <div onClick={(e) => e.stopPropagation()}>
            <SearchButton
              handleSearch={handleSearch}
              headerName="Work Period"
              columnKey="workperiod"
              key={key + "workperiod"}
            />
          </div>
        ),
        dataIndex: "workperiod",
        key: "workperiod",
        width: "200px",
        render: (record: any) => <>{formatWorkdays(record)}</>,
        showSorterTooltip: false,
        sorter: (a: any, b: any) => {
          return a["workperiod"]
            .toString()
            .localeCompare(b["workperiod"].toString());
        },
      },
      {
        title: (
          <div onClick={(e) => e.stopPropagation()}>
            <SearchButton
              handleSearch={handleSearch}
              headerName="Start time"
              columnKey="time_start"
              key={key + "time_start"}
            />
          </div>
        ),
        dataIndex: "time_start",
        key: "time_start",
        width: "150px",
        showSorterTooltip: false,
        sorter: (a: any, b: any) => {
          return a["time_start"]
            .toString()
            .localeCompare(b["time_start"].toString());
        },
      },
      {
        title: (
          <div onClick={(e) => e.stopPropagation()}>
            <SearchButton
              handleSearch={handleSearch}
              headerName="End time"
              columnKey="time_end"
              key={key + "time_end"}
            />
          </div>
        ),
        dataIndex: "time_end",
        key: "time_end",
        width: "150px",
        showSorterTooltip: false,
        sorter: (a: any, b: any) => {
          return a["time_end"]
            .toString()
            .localeCompare(b["time_end"].toString());
        },
      },
    ];
  }
};

const administrationPolicyColumnKeys = (key: any, handleSearch: any) => {
  // Function to capitalize the first letter of leaves
  const formatWorkdays = (str: string) => {
    if (str === "offday") {
      return "Off day";
    } else {
      return "Full day";
    }
  };

  return [
    {
      title: (
        <div onClick={(e) => e.stopPropagation()}>
          <SearchButton
            handleSearch={handleSearch}
            headerName="Policy name"
            columnKey="policy_name"
            key={key + "policy_name"}
          />
        </div>
      ),
      dataIndex: "policy_name",
      key: "policy_name",
      width: "200px",
      isActive: true,
      showSorterTooltip: false,
      sorter: (a: any, b: any) => {
        return a["policy_name"]
          .toString()
          .localeCompare(b["policy_name"].toString());
      },
    },
    {
      title: (
        <div onClick={(e) => e.stopPropagation()}>
          <SearchButton
            handleSearch={handleSearch}
            headerName="Filename"
            columnKey="filename"
            key={key + "filename"}
          />
        </div>
      ),
      dataIndex: "filename",
      key: "filename",
      width: "200px",
      showSorterTooltip: false,
      sorter: (a: any, b: any) => {
        return a["filename"].toString().localeCompare(b["filename"].toString());
      },
      // render: (record: any) => <>{formatWorkdays(record)}</>,
    },
  ];
};

const announcementColumnKeys = (key: any, handleSearch: any) => {
  // Function to capitalize the first letter of leaves
  const formatWorkdays = (str: string) => {
    if (str === "offday") {
      return "Off day";
    } else {
      return "Full day";
    }
  };

  return [
    {
      title: (
        <div onClick={(e) => e.stopPropagation()}>
          <SearchButton
            handleSearch={handleSearch}
            headerName="Announcement name"
            columnKey="announcement_name"
            key={key + "announcement_name"}
          />
        </div>
      ),
      dataIndex: "announcement_name",
      key: "announcement_name",
      width: "250px",
      isActive: true,
      showSorterTooltip: false,
      sorter: (a: any, b: any) => {
        return a["announcement_name"]
          .toString()
          .localeCompare(b["announcement_name"].toString());
      },
    },
    {
      title: (
        <div onClick={(e) => e.stopPropagation()}>
          <SearchButton
            handleSearch={handleSearch}
            headerName="File name"
            columnKey="filename"
            key={key + "filename"}
          />
        </div>
      ),
      dataIndex: "filename",
      key: "filename",
      width: "200px",
      showSorterTooltip: false,
      sorter: (a: any, b: any) => {
        return a["filename"].toString().localeCompare(b["filename"].toString());
      },
      // render: (record: any) => <>{formatWorkdays(record)}</>,
    },
  ];
};

const businessUnitFormList: any = [
  {
    label: "Strategic business unit ID",
    name: "id",
    required: true,
    type: "input",
  },
  {
    label: "Strategic business unit name",
    name: "name",
    required: true,
    type: "input",
  },
];

const projectTagsColumnKeys = (key: any, handleSearch: any) => {
  return [
    {
      title: (
        <SearchButton
          handleSearch={handleSearch}
          headerName="Group"
          columnKey="group"
          key={key + "group"}
        />
      ),
      dataIndex: "group",
      key: "group",
      isActive: true,
      isGroup: true,
    },
    {
      title: (
        <SearchButton
          handleSearch={handleSearch}
          headerName="Project tags"
          columnKey="name"
          key={key + "name"}
        />
      ),
      dataIndex: "name",
      key: "name",
      isActive: true,
      tag: true,
    },
    {
      title: (
        <SearchButton
          handleSearch={handleSearch}
          headerName="Description"
          columnKey="description"
          key={key + "description"}
        />
      ),
      dataIndex: "description",
      key: "description",
    },
    {
      title: "No. of projects",
      dataIndex: "projects_count",
      key: "projects_count",
    },
  ];
};

const projectTagsFormList: any = [
  {
    label: "Project tag name",
    name: "name",
    required: true,
    type: "input",
  },
  {
    type: "grid",
    children: [
      {
        label: "Color",
        name: "color",
        type: "colorPicker",
        required: true,
      },
      {
        notForm: true,
        label: "No. of projects",
        name: "projects_count",
        type: "disabled",
      },
    ],
  },
  {
    label: "Group",
    name: "group_uuid",
    required: true,
    type: "select",
    optionLabel: "group",
    optionKey: "group_uuid",
  },
  {
    label: "Description",
    name: "description",
    type: "textArea",
  },
];

const projectTagGroupFormList: any = [
  {
    label: "Group",
    name: "group",
    required: true,
    type: "input",
  },
  {
    label: "List of tags",
    name: "tags",
    type: "select",
    optionLabel: "name",
    optionKey: "tag_uuid",
    mode: "tags",
  },
];

const operatingOfficeColumnKeys = (key: any, handleSearch: any) => {
  return [
    {
      title: (
        <SearchButton
          handleSearch={handleSearch}
          headerName="Operating office"
          columnKey="name"
          key={key + "name"}
        />
      ),
      dataIndex: "office_name",
      key: "name",
      isActive: true,
    },
  ];
};

const operatingOfficeFormList: any = [
  {
    label: "Operating office",
    name: "name",
    required: true,
    type: "input",
  },
  {
    label: "Country",
    name: "country",
    required: true,
    type: "select",
    optionLabel: "name",
    optionKey: "alpha-3",
    extraKey: "country",
    // relayFrom: "region",
  },
];

const countryColumnKeys = (key: any, handleSearch: any) => {
  return [
    {
      title: (
        <SearchButton
          handleSearch={handleSearch}
          headerName="Country code"
          columnKey="alpha-3"
          key={key + "alpha-3"}
        />
      ),
      // width: 150,
      dataIndex: "alpha-3",
      key: "alpha-3",
    },
    {
      title: (
        <SearchButton
          handleSearch={handleSearch}
          headerName="Country"
          columnKey="name"
          key={key + "name"}
        />
      ),
      dataIndex: "name",
      key: "name",
    },
  ];
};

const regionColumnList = (key: any, handleSearch: any) => {
  return [
    {
      title: (
        <SearchButton
          handleSearch={handleSearch}
          headerName="Region code"
          columnKey="code"
          key={key + "code"}
        />
      ),
      // width: 150,
      dataIndex: "code",
      key: "code",
      isActive: true,
    },
    {
      title: (
        <SearchButton
          handleSearch={handleSearch}
          headerName="Region"
          columnKey="name"
          key={key + "name"}
        />
      ),
      dataIndex: "name",
      key: "name",
    },
  ];
};

const regionFormList: any = [
  {
    type: "grid",
    children: [
      {
        label: "Region Code",
        name: "code",
        required: true,
        type: "input",
        width: 18,
      },
      {
        label: "Region",
        name: "name",
        required: true,
        type: "input",
        width: 25,
      },
    ],
  },
  {
    label: "List of Countries",
    type: "divider",
  },

  {
    label: "Countries",
    name: "countries",
    required: true,
    type: "select-multiple",
    extraKey: "country",
    optionLabel: "name",
    optionKey: "alpha-3",
    list: true,
  },
];

const companyColumnList = (key: any, handleSearch: any) => {
  return [
    {
      title: (
        <SearchButton
          handleSearch={handleSearch}
          headerName="Company"
          columnKey="name"
          key={key + "name"}
        />
      ),
      dataIndex: "name",
      key: "name",
      isActive: true,
    },
    {
      title: (
        <SearchButton
          handleSearch={handleSearch}
          headerName="Domain"
          columnKey="domain"
          key={key + "domain"}
        />
      ),
      dataIndex: "domain",
      key: "domain",
    },
    {
      title: (
        <SearchButton
          handleSearch={handleSearch}
          headerName="Region"
          columnKey="region_name"
          key={key + "region_name"}
        />
      ),
      dataIndex: "region_name",
      key: "region",
    },
    {
      title: (
        <SearchButton
          handleSearch={handleSearch}
          headerName="Country"
          columnKey="country_name"
          key={key + "country_name"}
        />
      ),
      dataIndex: "country_name",
      key: "country",
    },
  ];
};
const companyFormList: any = [
  {
    label: "Company name",
    name: "name",
    required: true,
    type: "input",
  },
  {
    label: "Parent Company",
    name: "parent",
    required: false,
    optionLabel: "name",
    optionKey: "uuid",
    extraKey: "company",
    type: "select",
    relay: "company",
  },
  {
    label: "Domain",
    name: "domain",
    required: false,
    type: "input",
  },
  {
    label: "Region",
    name: "region",
    optionLabel: "name",
    optionKey: "code",
    extraKey: "region",
    relayIndexKey: "countries",
    relayLookupKey: "alpha-3",
    relayTo: "country",
    required: false,
    type: "select",
  },
  {
    label: "Country",
    name: "country",
    required: false,
    type: "select",
    optionLabel: "name",
    optionKey: "alpha-3",
    extraKey: "country",
    relayFrom: "region",
  },
  {
    label: "Subsidiaries (If any)",
    type: "divider",
  },

  {
    label: "Company name",
    name: "subsidiaries",
    required: false,
    type: "select-multiple",
    optionLabel: "name",
    optionKey: "uuid",
    extraKey: "company",
    list: true,
  },
];

const rolesColumnList = (key: any, handleSearch: any) => {
  return [
    {
      title: (
        <SearchButton
          handleSearch={handleSearch}
          headerName="Role"
          columnKey="name"
          key={key + "name"}
        />
      ),
      dataIndex: "name",
      key: "Role",
      isActive: true,
    },
    {
      title: (
        <SearchButton
          handleSearch={handleSearch}
          headerName="Description"
          columnKey="description"
          key={key + "description"}
        />
      ),
      dataIndex: "description",
      key: "description",
    },
  ];
};

const rolesFormList: any = [
  {
    label: "Role",
    name: "name",
    required: true,
    type: "input",
  },
  {
    label: "Description",
    name: "description",
    required: false,
    type: "textArea",
  },

  {
    label: "Permissions",
    type: "divider",
  },
  { type: "rolesPermissions", name: "permissions" },
];

const rolesConfigurationFormList: any = [
  {
    label: "Project manager",
    name: "project_manager",
    type: "select",
    optionLabel: "name",
    optionKey: "email",
    extraKey: "users",
    required: true,
  },
  {
    label: "Team members",
    type: "divider",
  },
  {
    name: "role_assignments",
    type: "role-dynamic-form",
    gap: "2%",
    pivotKey: "role",
    extraKey: "roles",
    optionKey: "name",
    children: [
      {
        label: "Role",
        name: "role",
        type: "select",
        optionLabel: "name",
        optionKey: "uuid",
        extraKey: "roles",
        required: true,
        filteredOn: "projects",
      },
      {
        label: "Assigned to",
        name: "appointee",
        type: "select",
        mode: "multiple",
        optionLabel: "name",
        optionKey: "email",
        extraKey: "users",
        required: true,
      },
    ],
  },
];

const rolesPermissionList = [
  {
    label: "Project visibility",
    description:
      "Control the visibility of projects within the organization's hierarchy",
    key: "project-visibility",
    keys: [
      {
        label: "View own projects",
        key: "Projects.OwnRead",
      },
      {
        label: "View projects under assignment clients",
        key: "Projects.ClientRead",
      },
      {
        label: "View ALL projects within tenant",
        key: "Projects.TenantRead",
      },
    ],
  },
  {
    label: "Manage projects",
    description: "Control various aspects of project management",
    keys: [
      {
        label: "Add new projects",
        key: "ProjectManagement.Create",
      },
      {
        label: "View projects",
        key: "ProjectManagement.Read",
      },
      { label: "Update projects", key: "ProjectManagement.Update" },
      { label: "Delete projects", key: "ProjectManagement.Delete" },
    ],
  },
];

const employeeFormList: any = [
  {
    type: "gridx",
    gap: "2%",
    gridLength: isMobile ? [] : [18.5, 9],
    children: [
      {
        label: "Employee name",
        name: "fullname_nric",
        required: true,
        type: "input",
      },
      {
        label: "NRIC/Passport number",
        name: "nric",
        required: true,
        type: "input",
      },
    ],
  },
  {
    type: "gridx",
    gap: "2%",
    gridLength: isMobile ? [] : [2, 2, 2],
    children: [
      {
        label: "Staff ID",
        name: "staff_id_num",
        required: true,
        type: "input",
      },
      {
        label: "Position",
        name: "position",
        required: true,
        type: "select-temporary",
        case: "position",
      },
      {
        label: "Number of annual leaves entitled",
        name: "num_leaves",
        required: true,
        type: "input",
      },
    ],
  },
  {
    type: "gridx",
    gap: "2%",
    gridLength: isMobile ? [] : [9, 9],
    children: [
      {
        label: "EPF number",
        name: "epf_num",
        required: true,
        type: "input",
      },
      {
        label: "Income Tax number",
        name: "income_tax_num",
        required: true,
        type: "input",
      },
    ],
  },
  {
    type: "gridx",
    gap: "2%",
    gridLength: isMobile ? [] : [2, 2, 2],
    children: [
      {
        label: "Status",
        name: "status",
        required: true,
        type: "select-status",
        case: "employeeStatus",
      },
      {
        label: "Joined date",
        name: "date_join",
        required: true,
        type: "datePicker",
      },
      {
        label: "End date",
        name: "date_end",
        required: true,
        type: "datePicker",
      },
      {
        label: "Claims",
        name: "claims",
        required: true,
        type: "claiminput",
      },
    ],
  },

  {
    type: "divider",
    label: "Microsoft account details",
  },
  {
    type: "gridx",
    gap: "2%",
    gridLength: [18.5, 9],
    children: [
      {
        label: "Email address",
        name: "email",
        required: true,
        type: "input",
      },
      {
        label: "Role",
        name: "roles",
        required: true,
        type: "select-temporary",
        case: "roles",
      },
    ],
  },
];

const internFormList: any = [
  {
    type: "gridx",
    gap: "2%",
    gridLength: isMobile ? [] : [18.5, 9],
    children: [
      {
        label: "Intern name",
        name: "fullname_nric",
        required: true,
        type: "input",
      },
      {
        label: "NRIC/Passport number",
        name: "nric",
        required: true,
        type: "input",
      },
    ],
  },
  {
    type: "gridx",
    gap: "2%",
    gridLength: isMobile ? [] : [2, 2, 2],
    children: [
      {
        label: "Intern ID",
        name: "staff_id_num",
        required: true,
        type: "input",
      },
      {
        label: "Position",
        name: "position",
        required: true,
        type: "select-temporary",
        case: "position",
      },
      {
        label: "Number of annual leaves entitled",
        name: "num_leaves",
        required: true,
        type: "input",
      },
    ],
  },
  {
    type: "gridx",
    gap: "2%",
    gridLength: isMobile ? [] : [2, 2, 2],
    children: [
      {
        label: "Status",
        name: "status",
        required: true,
        type: "select-status",
        case: "employeeStatus",
      },
      {
        label: "Joined date",
        name: "date_join",
        required: true,
        type: "datePicker",
      },
      {
        label: "End date",
        name: "date_end",
        required: true,
        type: "datePicker",
      },
    ],
  },

  {
    type: "divider",
    label: "Microsoft account details",
  },
  {
    type: "gridx",
    gap: "2%",
    gridLength: [18.5, 9],
    children: [
      {
        label: "Email address",
        name: "email",
        required: true,
        type: "input",
      },
      {
        label: "Role",
        name: "roles",
        required: true,
        type: "select-temporary",
        case: "roles",
      },
    ],
  },
];

const positionList: any = [
  {
    label: "Position code",
    name: "position_code",
    required: true,
    type: "input",
    disabledRequired: true,
  },
  {
    label: "Position name",
    name: "position_name",
    required: true,
    type: "input",
  },
];

const leavetypeList: any = [
  {
    label: "Leave type code",
    name: "leave_type_code",
    required: true,
    type: "input",
    disabledRequired: true,
  },
  {
    label: "Leave type name",
    name: "leave_type_name",
    required: true,
    type: "input",
  },
  {
    label: "Default number of days",
    name: "default_days",
    required: true,
    type: "input",
    render: (text: any) => String(text),
  },
  // {
  //   type: "gridx",
  //   gap: "5%",
  //   gridLength: isMobile ? [] : [9, 9],
  //   children: [
  //     {
  //       label: "Default number of days",
  //       name: "default_days",
  //       required: true,
  //       type: "input",
  //       render: (text: any) => String(text),
  //     },
  //     {
  //       label: "Attachment required",
  //       name: "attachment_required",
  //       required: true,
  //       type: "checkbox",
  //     },
  //   ],
  // },
  {
    label: "Attachment required",
    name: "attachment_required",
    required: true,
    type: "checkbox",
  },
  {
    label: "Balance claimable",
    name: "claimable",
    required: true,
    type: "checkbox",
  },
  {
    label: "Status",
    name: "status",
    required: false,
    type: "switch",
  },
];

const holidayList: any = [
  {
    label: "Holiday name",
    name: "holiday_name",
    required: true,
    type: "input",
  },
  {
    label: "Holiday date",
    name: "ph_date",
    required: true,
    type: "datePicker",
  },
];

const workdayList: any = [
  {
    label: "Weekday",
    name: "weekday",
    required: true,
    type: "input",
    disabledRequired: true,
  },
  {
    label: "Work period",
    name: "workperiod",
    required: true,
    type: "select-temporary",
    case: "workperiod",
  },
  {
    label: "Start time (24H format)",
    name: "time_start",
    required: true,
    type: "input",
  },
  {
    label: "End time (24H format)",
    name: "time_end",
    required: true,
    type: "input",
  },
];

const policyList: any = [
  {
    label: "Policy Name",
    name: "policy_name",
    required: true,
    type: "input",
    // disabledRequired: true,
  },
  {
    label: "Last updated on",
    name: "last_updated",
    required: true,
    type: "datePicker",
  },
  {
    label: "Current file",
    name: "uuid",
    required: true,
    type: "attachment_download",
  },
];

const announcementList: any = [
  {
    label: "Announcement Name",
    name: "announcement_name",
    required: true,
    type: "input",
    // disabledRequired: true,
  },
  {
    label: "Last updated on",
    name: "last_updated",
    required: true,
    type: "datePicker",
  },
  {
    label: "Current file",
    name: "uuid",
    required: true,
    type: "attachment_download",
  },
];

const leaveApprovalList: any = [
  {
    label: "Employee ID",
    name: "staff_id_num",
    // required: true,
    type: "disabled",
  },
  {
    label: "Employee name",
    name: "fullname_nric",
    required: true,
    type: "disabled",
  },
  {
    label: "",
    type: "divider",
  },
  {
    label: "Leave type",
    name: "leave_type",
    required: true,
    type: "disabled",
  },
  {
    label: "Description",
    name: "reason",
    required: true,
    type: "disabledDescription",
  },
  {
    type: "gridx",
    gap: "3%",
    gridLength: [5, 5, 1],
    children: [
      {
        label: "Start date",
        name: "start_date",
        required: true,
        type: "disabledDate",
      },
      {
        label: "End date",
        name: "end_date",
        required: true,
        type: "disabledDate",
      },
      {
        type: "grid",
        gap: "1%",
        children: [
          {
            type: "empty",
          },
          {
            label: "Days",
            name: "days",
            required: true,
            type: "disabled",
          },
        ],
      },
    ],
  },
  {
    label: "Remarks",
    name: "half_days",
    required: true,
    type: "disabledRemarks",
  },
  {
    label: "Attachment",
    name: "attachment",
    required: true,
    type: "attachment_download",
  },
  {
    label: "Status",
    name: "status",
    required: true,
    type: "disabled",
  },
  {
    label: "Claims",
    name: "requestedclaim",
    required: true,
    type: "claims",
  },
];

const leaveRejectionList: any = [
  ...leaveApprovalList,
  {
    label: "Rejection reason",
    name: "denial_reason",
    required: true,
    type: "disabled",
  },
];

const leaveCancellationList: any = [
  ...leaveApprovalList,
  {
    label: "Cancellation reason",
    name: "denial_reason",
    required: true,
    type: "disabled",
  },
];

const leaveConfigurationFormList: any = [
  {
    label: "",
    type: "divider",
  },
  {
    label: "Leave type",
    name: "leave_type",
    required: true,
    type: "select-temporary",
    case: "leave_type",
  },
  {
    label: "Description",
    name: "reason",
    required: false,
    type: "textArea",
  },
  {
    type: "gridx",
    gap: "3%",
    gridLength: isMobile ? [3, 1, 2, 2] : [4, 1, 2, 1],
    children: [
      {
        label: "Date",
        name: "date_range",
        required: true,
        type: "datePicker",
      },
      {
        label: "Days",
        name: "days",
        required: false,
        type: "disabled",
      },
      {
        label: isMobile ? "Balance" : "Balance after approval",
        name: "leave_calculation",
        required: false,
        type: "disabled",
      },
      {
        label: isMobile ? "Half days" : "Any half days?",
        name: "half_days_option",
        required: false,
        type: "checkbox",
        default: "No",
      },
    ],
  },
  {
    label: "Half days ( AM: 0930 - 1330, PM: 1330 - 1730 )",
    name: "half_days",
    required: false,
    type: "select",
    case: "half_days",
    default: "No half days",
  },
  {
    label: "Attachment",
    name: "attachment",
    required: false,
    type: "upload-attachments",
  },

  {
    type: "gridx",
    gap: "3%",
    // gridLength: isMobile ? [3, 1, 2, 2] : [4, 1, 2, 1],
    children: [
      {
        label: "Claim Amount",
        name: "requestedclaim",
        required: false,
        type: "requestedclaim",
        // category: "sick",
      },
      {
        label: "Claim Balance",
        name: "claims",
        required: true,
        type: "claims",
        // category: "sick",
      },
    ],
  },
];

const userRoleFormList: any = [
  {
    label: "Email",
    name: "email",
    required: true,
    type: "disabled",
    case: "email",
  },
  {
    label: "",
    type: "divider",
  },
  {
    label: "Role",
    name: "roles",
    required: false,
    type: "select-temporary",
    default: "Pending",
  },
  {
    label: "Employee ID & Name",
    name: "employee_id",
    required: false,
    type: "select-temporary",
    case: "employee_id",
    default: "Not assigned",
  },
];
