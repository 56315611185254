/**
 * One stop all AntD Icons
 */

import React from "react";
import * as antdIcon from "@ant-design/icons";
var fileIconJs = require("file-icons-js");

export const ant_icons = [
  "rocket",
  "dashboard",
  "map",
  "piechart",
  "area",
  "bar",
  "dot",
  "line",
  "radar",
  "heat",
  "fall",
  "rise",
  "stock",
  "boxplot",
  "fund",
  "slider",
  "setting",
  "bulb",
  "team",
  "user",
  "infocircle",
  "info",
  "barcode",
  "menu",
  "robot",
  "pushpin",
  "heart",
  "star",
  "gift",
  "comment",
  "notification",
  "clock",
  "edit",
  "image",
  "file",
  "experiment",
  "search",
  "mail",
  "aim",
  "alert",
  "tool",
  "contacts",
  "question",
  "close",
  "delete",
  "menufold",
  "menuunfold",
  "ExclamationCircleOutlined",
];

export const GetFileType = (filename: string) => {
  return fileIconJs.getClassWithColor(filename);
};

export const GetAntIcon = (type: string) => {
  if (type === undefined || type === null) return <></>;
  switch ((type as string).toLowerCase()) {
    case "map":
      return <antdIcon.EnvironmentOutlined className="antd-icon" />;
    case "number":
      return <antdIcon.NumberOutlined className="antd-icon" />;
    case "plus":
      return <antdIcon.PlusOutlined className="antd-icon" />;
    case "minus":
      return <antdIcon.MinusOutlined className="antd-icon" />;
    case "delete":
      return <antdIcon.DeleteOutlined className="antd-icon" />;
    case "pushpin":
      return <antdIcon.PushpinOutlined className="antd-icon" />;
    case "database":
      return (
        <antdIcon.DatabaseOutlined
          style={{ color: "white" }}
          className="antd-icon"
        />
      );
    case "contacts":
    case "contact":
      return <antdIcon.ContactsOutlined className="antd-icon" />;
    case "refresh":
      return <antdIcon.SyncOutlined className="antd-icon" />;
    case "tool":
      return <antdIcon.ToolOutlined className="antd-icon" />;
    case "bank":
      return <antdIcon.BankOutlined className="antd-icon" />;
    case "search":
      return <antdIcon.SearchOutlined className="antd-icon" />;
    case "gift":
      return <antdIcon.GiftOutlined className="antd-icon" />;
    case "pound":
      return <antdIcon.PoundOutlined className="antd-icon" />;
    case "dollar":
      return <antdIcon.DollarOutlined className="antd-icon" />;
    case "euro":
      return <antdIcon.EuroCircleOutlined className="antd-icon" />;
    case "aim":
      return <antdIcon.AimOutlined className="antd-icon" />;
    case "alert":
      return <antdIcon.AlertOutlined className="antd-icon" />;
    case "like":
      return <antdIcon.LikeOutlined className="antd-icon" />;
    case "dislike":
      return <antdIcon.DislikeOutlined className="antd-icon" />;
    case "scan":
      return <antdIcon.ScanOutlined className="antd-icon" />;
    case "shop":
      return <antdIcon.ShopOutlined className="antd-icon" />;
    case "mail":
      return <antdIcon.MailOutlined className="antd-icon" />;
    case "lock":
      return <antdIcon.LockOutlined className="antd-icon" />;
    case "unlock":
      return <antdIcon.UnlockOutlined className="antd-icon" />;
    case "experiment":
      return <antdIcon.ExperimentOutlined className="antd-icon" />;
    case "trophy":
      return <antdIcon.TrophyOutlined className="antd-icon" />;
    case "happy":
      return <antdIcon.SmileOutlined className="antd-icon" />;
    case "meh":
      return <antdIcon.MehOutlined className="antd-icon" />;
    case "sad":
      return <antdIcon.FrownOutlined className="antd-icon" />;
    case "img":
    case "image":
      return <antdIcon.FileImageOutlined className="antd-icon" />;
    case "edit":
      return <antdIcon.EditOutlined className="antd-icon" />;
    case "cloud":
      return <antdIcon.CloudOutlined className="antd-icon" />;
    case "download":
      return <antdIcon.CloudDownloadOutlined className="antd-icon" />;
    case "upload":
      return <antdIcon.CloudUploadOutlined className="antd-icon" />;
    case "sync":
      return <antdIcon.CloudSyncOutlined className="antd-icon" />;
    case "comment":
      return <antdIcon.CommentOutlined className="antd-icon" />;
    case "clock":
      return <antdIcon.ClockCircleOutlined className="antd-icon" />;
    case "barcode":
      return <antdIcon.BarcodeOutlined className="antd-icon" />;
    case "cart":
      return <antdIcon.ShoppingCartOutlined className="antd-icon" />;
    case "star":
      return <antdIcon.StarOutlined className="antd-icon" />;
    case "shopping":
      return <antdIcon.ShoppingOutlined className="antd-icon" />;
    case "tag":
      return <antdIcon.TagOutlined className="antd-icon" />;
    case "robot":
      return <antdIcon.RobotOutlined className="antd-icon" />;
    case "leftarrow":
      return <antdIcon.LeftOutlined className="antd-icon" />;
    case "rightarrow":
      return <antdIcon.RightOutlined className="antd-icon" />;
    case "downarrow":
      return <antdIcon.DownOutlined className="antd-icon" />;
    case "piechart":
      return <antdIcon.PieChartOutlined className="antd-icon" />;
    case "setting":
    case "settings":
      return (
        <antdIcon.SettingOutlined
          style={{ color: "white" }}
          className="antd-icon"
        />
      );
    case "dashboard":
      return <antdIcon.DashboardOutlined className="antd-icon" />;
    case "dash":
      return <antdIcon.DashOutlined className="antd-icon" />;
    case "small-dash":
      return <antdIcon.SmallDashOutlined className="antd-icon" />;
    case "logout":
      return (
        <antdIcon.LogoutOutlined
          style={{ color: "white" }}
          className="antd-icon"
        />
      );
    case "bulb":
      return <antdIcon.BulbOutlined className="antd-icon" />;
    case "heart":
      return <antdIcon.HeartOutlined className="antd-icon" />;
    case "team":
      return <antdIcon.TeamOutlined className="antd-icon" />;
    case "user":
      return (
        <antdIcon.UserOutlined
          style={{ color: "white" }}
          className="antd-icon"
        />
      );
    case "email":
      return <antdIcon.UserOutlined className="antd-icon" />;
    case "file":
      return <antdIcon.FileOutlined className="antd-icon" />;
    case "rocket":
      return <antdIcon.RocketOutlined className="antd-icon" />;
    case "infocircle":
      return <antdIcon.InfoCircleOutlined className="antd-icon" />;
    case "info":
      return <antdIcon.InfoOutlined className="antd-icon" />;
    case "menu":
      return <antdIcon.MenuOutlined className="antd-icon" />;
    case "notification":
      return <antdIcon.NotificationOutlined className="antd-icon" />;
    case "announcement":
      return <antdIcon.SoundOutlined className="antd-icon" />;
    case "coffee":
      return <antdIcon.CoffeeOutlined className="antd-icon" />;
    case "question":
      return <antdIcon.QuestionOutlined className="antd-icon" />;
    case "question3":
      return <antdIcon.QuestionCircleOutlined className="antd-icon" />;
    case "question2":
      return (
        <antdIcon.QuestionCircleOutlined
          style={{ color: "red" }}
          className="antd-icon"
        />
      );
    case "folder":
      return (
        <antdIcon.FolderTwoTone
          style={{ fontSize: "20px" }}
          twoToneColor={"#F8D775"}
          className="antd-icon"
        />
      );
    case "folder-outline":
      return <antdIcon.FolderOutlined className="antd-icon" />;
    case "borderless":
      return <antdIcon.BorderlessTableOutlined className="antd-icon" />;
    case "back":
      return (
        <antdIcon.ArrowLeftOutlined
          style={{ fontSize: "15px" }}
          className="antd-icon"
        />
      );
    case "deploymentunit":
      return <antdIcon.DeploymentUnitOutlined className="antd-icon" />;
    case "compass":
      return <antdIcon.CompassOutlined className="antd-icon" />;
    case "layout":
      return <antdIcon.LayoutOutlined className="antd-icon" />;
    case "close":
      return <antdIcon.CloseOutlined className="antd-icon" />;
    case "pdf":
      return <antdIcon.FilePdfOutlined className="antd-icon" />;
    case "arrowexpand":
      return <antdIcon.ArrowsAltOutlined className="antd-icon" />;
    case "expand":
      return <antdIcon.ExpandOutlined className="antd-icon" />;
    case "compress":
      return <antdIcon.CompressOutlined className="antd-icon" />;
    case "collapse":
      return <antdIcon.ShrinkOutlined className="antd-icon" />;
    case "comingsoon":
      return <antdIcon.FieldTimeOutlined className="antd-icon" />;
    case "ellipsis":
      return <antdIcon.EllipsisOutlined className="antd-icon" />;
    case "area":
      return <antdIcon.AreaChartOutlined className="antd-icon" />;
    case "bar":
      return <antdIcon.BarChartOutlined className="antd-icon" />;
    case "dot":
      return <antdIcon.DotChartOutlined className="antd-icon" />;
    case "line":
      return <antdIcon.LineChartOutlined className="antd-icon" />;
    case "radar":
      return <antdIcon.RadarChartOutlined className="antd-icon" />;
    case "heat":
      return <antdIcon.HeatMapOutlined className="antd-icon" />;
    case "fall":
      return <antdIcon.FallOutlined className="antd-icon" />;
    case "rise":
      return <antdIcon.RiseOutlined className="antd-icon" />;
    case "stock":
      return <antdIcon.StockOutlined className="antd-icon" />;
    case "boxplot":
      return <antdIcon.BoxPlotOutlined className="antd-icon" />;
    case "fund":
      return <antdIcon.FundOutlined className="antd-icon" />;
    case "slider":
      return <antdIcon.SlidersOutlined className="antd-icon" />;
    case "menufold":
      return <antdIcon.MenuFoldOutlined className="antd-icon" />;
    case "menuunfold":
      return <antdIcon.MenuUnfoldOutlined className="antd-icon" />;
    case "check":
      return <antdIcon.CheckOutlined />;
    case "group":
      return <antdIcon.GroupOutlined className="antd-icon" />;
    case "ungroup":
      return <antdIcon.UngroupOutlined className="antd-icon" />;
    case "exclamation":
      return <antdIcon.ExclamationCircleOutlined className="antd-icon" />;
    case "up":
      return <antdIcon.UpOutlined className="antd-icon" />;
    case "bell":
      return <antdIcon.BellOutlined className="antd-icon" />;
    case "project":
      return <antdIcon.ProjectOutlined className="antd-icon" />;
    case "global":
      return <antdIcon.GlobalOutlined className="antd-icon" />;
    case "linkedin":
      return <antdIcon.LinkedinOutlined className="antd-icon" />;
    case "export":
      return <antdIcon.ExportOutlined className="antd-icon" />;
    case "app":
      return <antdIcon.AppstoreOutlined className="antd-icon" />;
    case "ascending":
      return <antdIcon.SortAscendingOutlined className="antd-icon" />;
    case "descending":
      return <antdIcon.SortDescendingOutlined className="antd-icon" />;
    case "filter":
      return <antdIcon.FilterOutlined className="antd-icon" />;
    case "thunderbolt":
      return <antdIcon.ThunderboltOutlined className="antd-icon" />;
    case "calendar":
      return <antdIcon.CalendarOutlined className="antd-icon" />;
    case "user-add":
      return <antdIcon.UserAddOutlined className="antd-icon" />;
    default:
      return <antdIcon.HolderOutlined className="antd-icon" />;
  }
};
