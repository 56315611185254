import React, { useContext, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import {
  Space,
  Tabs,
  message,
  Upload,
  Spin,
  Progress,
  Modal,
  Button,
} from "antd";
import type { UploadProps } from "antd";
import LookupManager from "./LookupManager";
import { setContainer } from "../utils/containers";
import { tab } from "@testing-library/user-event/dist/tab";
import { HomeContext } from "./Home";
import zIndex from "@mui/material/styles/zIndex";
import { InboxOutlined } from "@ant-design/icons";
import {
  addExcelItem,
  updateExcelFileItem,
} from "../services/api-server/excel_file";
import { deleteFile, uploadFile } from "../services/api-server/filetransfer";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import Emitter from "../utils/emitter";
import { socket } from "../utils/socket";
import { Margin } from "@mui/icons-material";

const { Dragger } = Upload;

function GenericTabContainer(props: any) {
  const role = props.params?.userRole[0];
  const context: any = useContext(HomeContext);
  const [loading, setLoading] = useState(false);
  const [uploadPercent, setUploadPercent] = useState(0);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [tabkey, setTabKey] = useState("lookup-manager");

  const changeContent = () => {
    switch (tabkey) {
      case "lookup-manager":
        return (
          <LookupManager
            tabKey={"excel"}
            subTabKey={"Excel file"}
            containerTitle={""}
          />
        );
      default:
        return <></>;
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false); // Hide modal on cancel or close
  };

  return (
    <div className="generic-container" style={{ gap: "20px" }}>
      <Space direction="vertical">
        <span
          style={{
            fontSize: "20px",
            fontWeight: "600",
            color: "rgba(0, 0, 0, 0.85)",
          }}
        >
          {props.params.propTitle}
        </span>
        <span style={{ fontSize: "14px" }}>
          Upload your master Excel files here to easily generate detailed
          reports.
        </span>
      </Space>
      <Upload.Dragger
        name="file"
        multiple
        accept=".xlsx, .xls"
        customRequest={async (info: any) => {
          const UploadformData = new FormData();
          const filename = info.file.name;
          const imgUUID = uuidv4() + "." + filename.split(".").pop();
          UploadformData.append("file", info.file);
          UploadformData.append("filename", filename);
          UploadformData.append("uuid", imgUUID);
          UploadformData.append("size", info.file.size);
          UploadformData.append(
            "upload_date",
            moment().format("YYYY-MM-DD HH:mm:ss")
          );
          setLoading(true);
          setUploadPercent(0); // Reset progress bar
          setIsModalVisible(true);
          // Simulate file upload and progress
          const fakeUpload = setInterval(() => {
            setUploadPercent((prev) => {
              const newPercent = prev + 10;
              if (newPercent >= 100) {
                clearInterval(fakeUpload);
              }
              return newPercent;
            });
          }, 3000); // Simulate upload progress
          uploadFile(UploadformData, {
            upload_path: "../katimas_upload_excel",
          })
            .then((data: any) => {
              // console.log("data", data);
              setLoading(false);
              setUploadPercent(100); // Upload complete
              Emitter.emit("alert", {
                type: "success",
                message: `${data?.data?.results?.filename} has been successfully uploaded.`,
                description: "",
                top: true,
                closeable: false,
                timeout: 3000,
              });
              // console.log("start");

              // socket.emit("excel_datatable_update", `ping excel datatable`);
              socket.emit(
                "excel_datatable_update",
                `Hello from React frontend! ${socket.id}`
              );
            })
            .catch((error: any) => {
              console.log("error", error);
              setLoading(false);
              setUploadPercent(0); // Reset on error
              Emitter.emit("alert", {
                type: "error",
                message: `Excel upload failed. Please try again.`,
                description: "",
                top: true,
                closeable: false,
                timeout: 3000,
              });
            })
            .finally(() => {
              setIsModalVisible(false); // Hide modal when upload finishes
            });
        }}
        onDrop={async (e) => {
          console.log("Dropped files", e.dataTransfer.files);
          const files = e.dataTransfer.files;
        }}
        itemRender={() => null} // Hide the upload item list
      >
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text" style={{ fontSize: "14px" }}>
          Drag file to this area to upload or{" "}
          <span style={{ color: "#F7B01F" }}>upload file</span>
        </p>
        <p className="ant-upload-hint" style={{ fontSize: "14px" }}>
          Supports both single and bulk uploads, accepting only Excel files
          (.xlsx format).
        </p>
      </Upload.Dragger>
      <Modal
        title={
          <div
            style={{
              padding: "15px",
              backgroundColor: "#2C378E",
              color: "white",
              minHeight: "25px",
              maxHeight: "25px",
            }}
          >
            File upload
          </div>
        }
        open={isModalVisible}
        // onCancel={handleCancel}
        centered
        closable={false} // Disable closing during upload
        maskClosable={false} // Disable closing on outside click
        // footer={[
        //   <div style={{ padding: "16px" }}>
        //     <Button
        //       key="back"
        //       onClick={handleStopUpload}
        //       style={{ width: "100%" }}
        //     >
        //       Cancel
        //     </Button>
        //   </div>,
        // ]}
      >
        <p
          style={{
            display: "inline",
            marginRight: "10px",
            marginLeft: "15px",
          }}
        >
          Uploading {uploadPercent}% complete...
        </p>
        <Progress
          style={{ marginRight: "100px, 0", padding: "0 20px 0 20px" }}
          percent={uploadPercent}
          status={uploadPercent < 100 ? "active" : "success"}
        />
      </Modal>
      {changeContent()}
    </div>
  );
}

export default GenericTabContainer;
