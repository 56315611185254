import axios from "axios";
import { url, AuthHeader, serverConnection } from "../../utils/_exports";
const apiURL = "loan";

// policy GET route
export const getLoanList = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${serverConnection}/${apiURL}/`, { ...AuthHeader })
      .then((_data: any) => {
        if (_data?.data.data.data && _data.data.data.data.length) {
          resolve(_data?.data.data.data);
        } else {
          resolve(null);
        }
      })
      .catch((e: any) => {
        reject(e);
      });
  });
};

// policy GET route by policy id
export const getLoanListById = (policy_id: string) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${serverConnection}/${apiURL}/`, {
        params: { policy_id },
        ...AuthHeader,
      })
      .then((_data: any) => {
        if (_data?.data.data.data && _data.data.data.data.length) {
          resolve(_data?.data.data.data);
        } else {
          resolve(null);
        }
      })
      .catch((e: any) => {
        reject(e);
      });
  });
};

// loan GET route by selected field
export const getLoanListByField = (param: any) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${serverConnection}/${apiURL}`, {
        params: param,
        ...AuthHeader,
      })

      .then((_data: any) => {
        if (_data?.data) {
          resolve(_data?.data);
        } else {
          resolve(null);
        }
      })
      .catch((e: any) => {
        reject(e);
      });
  });
};

// policy POST route
export const addLoanItem = (data: any) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${serverConnection}/${apiURL}/`, data, AuthHeader)
      .then((_data: any) => {
        resolve(_data?.data.data.data);
      })
      .catch((e: any) => {
        reject(e);
      });
  });
};

// to save policy file
export const updateLoanFileItem = (_ids: Array<any>, data: any) => {
  const _data = {
    // query: { _id: { $in: _ids } },
    query: { _id: _ids[0] },
    data: data,
  };
  return new Promise((resolve, reject) => {
    axios
      // .put(`${url}/${apiURL}/`, _data, AuthHeader)
      .put(`${serverConnection}/${apiURL}/saveFile`, _data, AuthHeader)
      .then((_data: any) => {
        //console.log(_data);
        resolve(_data?.data?.data?.data);
      })
      .catch((e: any) => {
        //console.log(e);
        reject(e);
      });
  });
};

// policy PUT route
export const updateLoanItem = (_ids: Array<any>, data: any) => {
  const _data = {
    query: { _id: _ids[0] },
    data: data,
  };
  return new Promise((resolve, reject) => {
    axios
      .put(`${serverConnection}/${apiURL}/`, _data, AuthHeader)
      .then((_data: any) => {
        resolve(_data?.data.data.data);
      })
      .catch((e: any) => {
        reject(e);
      });
  });
};

// policy DELETE route
export const deleteLoanItem = (_id: any) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(`${serverConnection}/${apiURL}`, {
        params: _id,
        ...AuthHeader,
      })
      .then((_data: any) => {
        resolve(_data?.data);
      })
      .catch((e: any) => {
        reject(e);
      });
  });
};

// loan POST selected route
export const generateLoanReport = (date: any, data: any) => {
  return new Promise<void>((resolve, reject) => {
    axios
      .post(`${serverConnection}/generate_excel/excel_report`, data, {
        ...AuthHeader,
        responseType: "blob",
      })
      .then((response: any) => {
        const url = window.URL.createObjectURL(response.data);
        const a = document.createElement("a");
        a.href = url;
        a.download = `COLLECTION REPORT ${date}.xlsx`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
        resolve();
      })
      .catch((e: any) => {
        reject(e);
      });
  });
};
