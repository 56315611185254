/**
 * Main source of the client react system
 * 	- Stores & Create routes & pages
 * 	- Auto navigate logic
 *  - Check logins & tokens
 *  -
 */

import React, { useEffect, useMemo, useState } from "react";
import logo from "./logo.svg";
import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  Outlet,
} from "react-router-dom";
import { Alert, ConfigProvider, theme } from "antd";
import Location from "./utils/location";
import Home from "./containers/Home";
import LoginPage from "./containers/LoginPage";
import LoginSuccess from "./services/LoginSuccess";
import LoginFailed from "./services/LoginFailed";
import jwtDecode from "jwt-decode";
import { initialExtraMenu, initialMainMenu } from "./services/MainMenu";
import { setContainer } from "./utils/containers";
// import { addUser, getUser } from "./services/api-server/user";
import Emitter from "./utils/emitter";
import { socket } from "./utils/socket";
import { logout, getAlertStyle } from "./utils/utils";
import { refreshToken } from "./services/api-server/usertoken";
import NoAccess from "./containers/NoAccess";
import { superAdminRole } from "./utils/_exports";
import { getAppRoles, getAppUsers } from "./services/api-server/graphql";
import { GetAntIcon } from "./utils/ant_icons";
// import RegistrationPage from "./containers/RegistrationPage";
import { ConsoleSqlOutlined } from "@ant-design/icons";
import { get } from "lodash";
import { getLookupItem } from "./utils/lookup_list";
import { local } from "d3";
// import {
//     getEmployeeByID,
//     getEmployeeByQuery,
// } from "./services/api-server/employee";
export const Mode = `${process.env.REACT_APP_MODE}`;
export let Tenant = `elemental`;

const { defaultAlgorithm, darkAlgorithm } = theme;

if (localStorage.getItem("theme")) {
  const theme = localStorage.getItem("theme");
  if (theme == "dark") {
    document.body.classList.toggle("dark-mode", true);
  } else if (theme == "light") {
    document.body.classList.toggle("dark-mode", false);
  }
} else {
  localStorage.setItem("theme", Mode);
  if (Mode == "dark") {
    document.body.classList.toggle("dark-mode", true);
  } else if (Mode == "light") {
    document.body.classList.toggle("dark-mode", false);
  }
}

let tokenCheck: any = null;
const restricted = ["/", "loginsuccess", "loginfailed", "logout"];
const App = () => {
  //console.log("Check");
  let isLogin = false;
  let shouldRedirect = false;
  let currentDate: any = Date.now() / 1000;
  let timeout: any = null;

  const restricted = [
    "",
    "/",
    "/loginsuccess",
    "/loginsuccess/",
    "/loginfailed/",
    "/loginfailed",
    "/logout",
    "/logout/",
  ];

  const [user, setUser] = useState<any>(null);
  const [userRole, setUserRole] = useState<any>(null);
  const [menu, setMenu] = useState<[]>([]);
  const [menuRoutes, setMenuRoutes] = useState<[]>([]);
  const [adminMenuRoutes, setAdminMenuRoutes] = useState<[]>([]);
  const [currentLocation, setCurrentLocation] = useState<any>(
    window.location.pathname
  );
  const [accessTokenDecoded, setAccessTokenDecoded] = useState<any>(null);
  const [idTokenDecoded, setIDTokenDecoded] = useState<any>(null);

  const [alertmsg, setAlert] = useState<any>(null);

  // This is to define the current url location of the user
  // This is mostly use to define
  const onLocationChange = (location: Location) => {
    setCurrentLocation(location.pathname);
  };

  useEffect(() => {
    setUser({
      _id: "66a083bb43984c57b0bcda4d",
      fullname_nric: "Mathew Hu Kuok Wei",
      nric: "1232132133123",
      staff_id_num: "Mathew",
      position: "HR",
      num_leaves: "10",
      epf_num: "sadsad",
      income_tax_num: "123213",
      status: "active",
      date_join: "2024-07-10 00:00:00",
      claims: 900,
      email: "mathewhu22@rajang.com",
      modified_on: 1722308610411,
      leave_entitled: [
        {
          leave_type: "annual",
          entitled: 10,
          used: 4,
          pending: 3,
          leave_status: true,
        },
        {
          leave_type: "sick",
          entitled: 12,
          used: 0,
          pending: 3,
          leave_status: true,
        },
        {
          leave_type: "unpaid",
          entitled: 999,
          used: 22,
          pending: 0,
          leave_status: true,
        },
        {
          leave_type: "medical",
          entitled: 0,
          used: 0,
          pending: 0,
          leave_status: true,
        },
        {
          leave_type: "marriage",
          entitled: 5,
          used: 0,
          pending: 0,
          leave_status: true,
        },
        {
          leave_type: "harraz",
          entitled: 0,
          used: 0,
          pending: 0,
          leave_status: true,
        },
        {
          leave_type: "irham",
          entitled: 0,
          used: 0,
          pending: 0,
          leave_status: true,
        },
        {
          leave_type: "jas",
          entitled: 0,
          used: 0,
          pending: 0,
          leave_status: false,
        },
        {
          leave_type: "mat",
          entitled: 0,
          used: 0,
          pending: 0,
          leave_status: false,
        },
      ],
      resume: [
        {
          name: "5 Canara Bank Company SnapShot (1).docx",
          uuid: "a57fef72-02ac-4e9c-a3a9-eeb51feb30c2.docx",
        },
      ],
    });
    setUserRole("admin");
    socketController();
  }, []);

  // Quick simple check for sign-ins
  // localStorage.removeItem("isLogin");
  if (!restricted.includes(currentLocation)) {
    // console.log("restricted", restricted);
    // console.log("currentLocation", currentLocation);
    // if (
    //   (!localStorage.getItem("accessToken") &&
    //     !localStorage.getItem("idToken")) ||
    //   localStorage.getItem("accessToken") === "undefined" ||
    //   localStorage.getItem("idToken") === "undefined"
    // ) {
    //   localStorage.removeItem("accessToken");
    //   localStorage.removeItem("idToken");
    //   shouldRedirect = true;
    // } else {
    //   isLogin = true;
    // }
    isLogin = true;
  }

  // Get user information from mongoDB, and populate user info into the App
  // const getUserInfo = (email: any, name: any) => {
  //     // setUser(email);
  //     getUser(email)
  //         .then((data: any) => {
  //             if (data) {
  //                 setUser(data[0]);
  //             } else {
  //                 addUser({
  //                     name: name,
  //                     email: email,
  //                 })
  //                     .then((data: any) => {
  //                         // console.log(data);
  //                         setUser(data);
  //                     })
  //                     .catch((error: any) => {
  //                      //console.log(error);
  //                     });
  //             }
  //         })
  //         .catch((error: any) => {
  //             logout(true);
  //          //console.log(error);
  //         });
  // };

  // Get employee information from mongoDB, and populate user info into the App
  // const getEmployeeInfo = (employeeID: any) => {
  //     getEmployeeByID(employeeID)
  //         .then((data: any) => {
  //             setUser(data[0]);
  //         })
  //         .catch((error: any) => {
  //          //console.log(error);
  //         });
  // };

  // const getEmployeeInfoEmail = (email: any) => {
  //   // getEmployeeByQuery(query)
  //   getLookupItem("employee")
  //     .then((data: any) => {
  //       if (data) {
  //         const employee = data.find(
  //           (employee: any) => employee.email === email
  //         );
  //         if (employee) {
  //           setUser(employee);
  //         } else {
  //           localStorage.setItem(
  //             "LoginMessage",
  //             "Email is not assigned to employee yet. Please contact your administrator."
  //           );
  //           logout(true);
  //         }
  //       }
  //     })
  //     .catch((error: any) => {
  //       localStorage.setItem(
  //         "LoginMessage",
  //         "Error signing in. Please try again."
  //       );
  //       logout(true);
  //       //console.log(error);
  //     });
  // };

  // Emitter controller - Control events within the app itself
  const emitterController = () => {
    // Emitter.on("userSaved", (data: any) => {
    //     getUserInfo(data.email, data.name);
    // });

    Emitter.on("alert", (payload: any) => {
      if (payload) {
        if (payload.timeout) {
          if (timeout) {
            clearTimeout(timeout);
            timeout = null;
          }
          timeout = setTimeout(() => {
            setAlert(null);
          }, payload.timeout);
        }
        setAlert({
          type: payload.type,
          message: payload.message,
          description: payload.description,
          top: payload.top,
          closeable: payload.closable,
        });
      } else {
        setAlert(null);
      }
    });
  };

  // useEffect(() => {
  //   // Optional: Listen to the connect event
  //   socket.on("connect", () => {
  //     socket.emit("custom_event", `Hello from React frontend! ${socket.id}`);
  //     // console.log("Connected with socket ID:", socket.id);
  //   });
  // }, []);

  // Socket Controller - control events with the back-end server and will conduct all socket io messages
  const socketController = () => {
    const socketServer: string = process.env.REACT_APP_SOCKET_SERVER as string;
    // const socketIDToken: any = localStorage.getItem(`${Tenant}:idToken`);
    socket.on("connect", () => {
      socket.emit("custom_event", `Hello from React frontend! ${socket.id}`);
      // setInterval(() => {
      //   const start = Date.now();

      //   socket.emit("ping", () => {
      //     const duration = Date.now() - start;
      //     // console.log("Latency:", duration + "ms");
      //   });
      // }, 1000);
    });
    socket.on("connect_error", (err: any) => {
      // the reason of the error, for example "xhr poll error"
      console.log(err.message);

      // some additional description, for example the status code of the initial HTTP response
      console.log(err.description);

      // some additional context, for example the XMLHttpRequest object
      console.log(err.context);
    });
  };

  // Token Controller - to check initial token expiry and set tokens for user info usage
  // const tokenController = (
  //   email: any,
  //   idTokenDecoded: any,
  //   accessTokenDecoded: any
  // ) => {
  //   let currentDate = Date.now() / 1000;

  //   if (idTokenDecoded && accessTokenDecoded) {
  //     if (currentDate > accessTokenDecoded?.exp - 5 * 60) {
  //       // 5 mins before expiry
  //       if (currentDate > accessTokenDecoded?.exp + 30 * 60) {
  //         // 30 mins after expiry
  //         logout(true);
  //       } else {
  //         //RefreshToken
  //         refreshToken(email)
  //           .then((refreshed_token: any) => {
  //             setAccessTokenDecoded(jwtDecode(refreshed_token));
  //             Emitter.emit("refreshed", null);
  //           })
  //           .catch(() => {
  //             logout(true);
  //           });
  //       }
  //     }
  //   } else {
  //     logout(true);
  //   }
  // };

  // Check Login && Redirect
  // const getUserInfo = () => {
  //   if (!restricted.includes(currentLocation) && !user)
  //     try {
  //       const idToken_decoded: any = jwtDecode(
  //         localStorage.getItem("accessToken") || ""
  //       );
  //       const accessToken_decoded: any = jwtDecode(
  //         localStorage.getItem("idToken") || ""
  //       );
  //       const loginType = localStorage.getItem("loginType");

  //       // alert("Errorrrr " + idToken_decoded + accessToken_decoded);
  //       emitterController();
  //       socketController();
  //       tokenController(
  //         idToken_decoded.email,
  //         idToken_decoded,
  //         accessToken_decoded
  //       );
  //       setAccessTokenDecoded(accessToken_decoded);
  //       setIDTokenDecoded(idToken_decoded);
  //       // getAppRoles().then((values: any) => {
  //       //  //console.log(values);
  //       // });
  //       // getAppUsers().then((values: any) => {
  //       //  //console.log(values);
  //       // });

  //       getEmployeeInfoEmail(idToken_decoded.email);
  //       // if (loginType === "azure_ad") {
  //       //     // getUserInfo(idToken_decoded.preferred_username, idToken_decoded.name);
  //       //     getEmployeeInfoEmail(idToken_decoded.email);
  //       // } else if (loginType === "user_acc") {
  //       //     getEmployeeInfo(idToken_decoded.employee_id);
  //       // }

  //       // set all item in roles to lower case
  //       idToken_decoded.roles = idToken_decoded.roles.map((role: any) =>
  //         role.toLowerCase()
  //       );

  //       setUserRole(idToken_decoded.roles);
  //       // console.log("User Role: ", idToken_decoded.roles);
  //     } catch (err: any) {
  //       // alert("Error 4: " + err);
  //       logout(true);
  //     }
  // };

  useEffect(() => {
    emitterController();
    // console.log("currentLocation", currentLocation);
    // getUserInfo();
    // socket.on("refresh-employee-data", (payload: any) => {
    //     //RefreshToken
    //     const idToken_decoded: any = jwtDecode(
    //         localStorage.getItem("accessToken") || ""
    //     );
    //     refreshToken(idToken_decoded.email)
    //         .then((refreshed_token: any) => {
    //             setAccessTokenDecoded(jwtDecode(refreshed_token));
    //             Emitter.emit("refreshed", null);
    //             getUserInfo();
    //         })
    //         .catch(() => {
    //             logout(true);
    //         });
    // });
  }, [currentLocation]);

  // Interval check for token expiry of users
  // useEffect(() => {
  //   if (tokenCheck) clearInterval(tokenCheck);
  //   if (accessTokenDecoded && idTokenDecoded) {
  //     tokenCheck = setInterval(() => {
  //       tokenController(
  //         idTokenDecoded?.email,
  //         idTokenDecoded,
  //         accessTokenDecoded
  //       );
  //     }, 60000);
  //   }
  // }, [accessTokenDecoded, idTokenDecoded]);

  //   useEffect(() => {
  //     Emitter.on("USER_HAS_BEEN_UPDATED", getEmployeeInfoEmail);
  //   }, []);

  const loadMenu = () => {
    // console.log("userRole", userRole);
    if (userRole) {
      const mainMenu: any = initialMainMenu;
      const extraMenu: any = initialExtraMenu;
      let allMenu: any = [...mainMenu, ...extraMenu];

      const menuRoutes: any = [];
      const adminMenuRoutes: any = [];
      let params: any = { user, userRole };
      const traverse = (menuItems: any, parentPath: any = "") => {
        menuItems.forEach((item: any) => {
          let route = null;
          if (item.to) {
            if (item.requires_admin) {
              if (item.container === "tabContainer") {
                route = (
                  <>
                    <Route
                      key={item.key}
                      path={item.to.split("/").pop()}
                      element={
                        params?.userRole?.includes(superAdminRole) ? (
                          setContainer(
                            item.container,
                            item.propTitle,
                            item.key,
                            {
                              ...params,
                              ...item,
                              userRole,
                            },
                            userRole,
                            user
                          )
                        ) : (
                          <NoAccess
                            break={true}
                            text={
                              "Oops, looks like you don't have the authorisation to view this page."
                            }
                            navigateTo={"/leave"}
                          />
                        )
                      }
                    ></Route>
                    <Route
                      key={"Configuration"}
                      path={item.to.split("/").pop() + "/configuration"}
                      element={
                        params?.userRole?.includes(superAdminRole) ? (
                          setContainer(
                            "configuration",
                            "Configuration",
                            "configuration",
                            {
                              key: "configuration-menu",
                              label: "Configuration",
                              propTitle: "Configuration",
                              container: "configuration",
                              icon: GetAntIcon("project"),
                              to: "/configuration",
                              requires_admin: true,
                              ...params,
                              userRole,
                            },
                            userRole,
                            user
                          )
                        ) : (
                          <NoAccess
                            break={true}
                            text={
                              "Oops, looks like you don't have the authorisation to view this page."
                            }
                          />
                        )
                      }
                    ></Route>
                  </>
                );
              } else {
                if (params?.userRole?.includes(superAdminRole)) {
                  route = (
                    <Route
                      key={item.key}
                      // path={"admin/" + item.to.split("/").pop()}
                      path={item.to.split("/").pop()}
                      element={setContainer(
                        item.container,
                        item.propTitle,
                        item.key,
                        { ...params, ...item },
                        userRole,
                        user
                      )}
                    />
                  );
                } else {
                  route = (
                    <Route
                      key={item.key}
                      // path={"admin/" + item.to.split("/").pop()}
                      path={item.to.split("/").pop()}
                      element={
                        <NoAccess
                          break={true}
                          text={
                            "Oops, looks like you don't have the authorisation to view this page."
                          }
                        />
                      }
                    />
                  );
                }
              }
            } else {
              if (
                item.container === "leaveContainer" ||
                item.container === "administrationContainer"
              ) {
                route = (
                  <>
                    <Route
                      key={item.key}
                      path={item.to.split("/").pop()}
                      element={setContainer(
                        item.container,
                        item.propTitle,
                        item.key,
                        {
                          ...params,
                          ...item,
                          userRole,
                          user,
                        },
                        userRole,
                        user
                      )}
                    ></Route>
                  </>
                );
              } else {
                route = (
                  <Route
                    key={item.key}
                    path={item.to.split("/").pop()}
                    element={setContainer(
                      item.container,
                      item.propTitle,
                      item.key,
                      { ...params, ...item, userRole },
                      userRole,
                      user
                    )}
                  />
                );
              }
            }
            if (item.requires_admin) {
              adminMenuRoutes.push({
                item: item,
                route: route,
              });
            } else {
              menuRoutes.push({
                item: item,
                route: route,
              });
            }
          }
          if (item.children && Array.isArray(item.children)) {
            traverse(item.children, parentPath + (item.to || ""));
          }
        });
      };
      traverse(allMenu);
      menuRoutes.push({
        item: null,
        route: <Route path="*" element={<NoAccess />} />,
      });
      adminMenuRoutes.push({
        item: null,
        route: <Route path="*" element={<NoAccess />} />,
      });
      setMenuRoutes(menuRoutes);
      setAdminMenuRoutes(adminMenuRoutes);
    }
  };

  // Load Menus and populate routes
  useEffect(() => {
    loadMenu();
    // socket.on("refresh-employee-data", (payload: any) => {
    //   if (user && user._id == payload.employee_id) {
    //     localStorage.setItem(
    //       "LoginMessage",
    //       "Your role has been updated, please login again."
    //     );
    //     logout(true);
    //   }
    //   // console.log("Menu changes");
    //   // loadMenu();
    // });
  }, [userRole, user]);

  return (
    <ConfigProvider theme={{ hashed: false, algorithm: defaultAlgorithm }}>
      <div className="main-page">
        <Router>
          <Location onChange={onLocationChange}></Location>
          {shouldRedirect ? <Navigate to="" /> : <></>}
          <Routes>
            <Route path="" element={<LoginPage userRole={userRole} />} />
            {/* <Route
                            path="registration"
                            element={<RegistrationPage />}
                        /> */}
            {/* <Route
              path="loginsuccess"
              element={<LoginSuccess accessTokenDecoded={accessTokenDecoded} />}
            />
            <Route path="loginfailed" element={<LoginFailed />} /> */}
            {isLogin ? (
              <>
                {" "}
                <Route
                  path=""
                  element={
                    <Home
                      userInfo={{ user, userRole }}
                      currentLocation={currentLocation}
                      menu={[...menuRoutes, ...adminMenuRoutes]}
                    />
                  }
                >
                  {menuRoutes.map((menuItem: any) => {
                    return menuItem.route;
                  })}
                  {adminMenuRoutes.map((menuItem: any) => {
                    return menuItem.route;
                  })}
                </Route>
              </>
            ) : (
              <></>
            )}
          </Routes>
        </Router>
        {alertmsg && (
          <Alert
            className={
              alertmsg?.top ? "alert-message-box-top" : "alert-message-box"
            }
            type={alertmsg?.type}
            message={alertmsg?.message}
            description={alertmsg?.description}
            showIcon
            closable={alertmsg?.closable || false}
            afterClose={() => setAlert(null)}
            style={{
              fontFamily: "Open Sans,sans-serif, arial",
              ...getAlertStyle(alertmsg?.type),
            }}
          />
        )}
      </div>
    </ConfigProvider>
  );
};

export default App;
