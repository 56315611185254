import React, { useContext, useEffect, useState, useRef } from "react";
import {
  Space,
  Tabs,
  Typography,
  Table,
  Button,
  Dropdown,
  Menu,
  Input,
  Upload,
  Modal,
  Form,
} from "antd";
import { HomeContext } from "./Home";
import { getLookupItem } from "../utils/lookup_list";
import { pdfjs } from "react-pdf";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { setupPdfJsWorker } from "../utils/pdfjs-worker-setup";
import { isMobile } from "react-device-detect";
import { fetchFile } from "../services/api-server/filetransfer";
import moment from "moment";
import {
  FileAddOutlined,
  FolderAddOutlined,
  FileOutlined,
  FolderOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { v4 as uuidv4 } from "uuid";
import {
  addExplorerItem,
  deleteExplorerItem,
  updateExplorerFileItem,
  updateExplorerItem,
} from "../services/api-server/explorer";

function ExplorerContainer(props: any) {
  const context: any = useContext(HomeContext);
  const [tabKey, setTabKey] = useState<any>("");
  const [tabKeys, setTabKeys] = useState([]);
  const [pdfURL, setPdfURL] = useState<any>(null);
  const [last_updated, setLast_updated] = useState<any>(null);
  const [directoryName, setDirectoryName] = useState<string | null>(null);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [createNewFolder] = Form.useForm();
  const CustomCloseIcon = () => (
    <CloseOutlined style={{ fontSize: "20px", color: "white" }} />
  );

  const role = props.params.userRole[0];
  const userData = props.params.user;
  console.log("userData", userData);

  useEffect(() => {
    setupPdfJsWorker();
  }, []);

  useEffect(() => {
    if (tabKey) {
    }
  }, [tabKey]);

  const showModal = () => {
    createNewFolder.resetFields();
    setIsModalVisible(true);
  };

  const hideModal = () => {
    createNewFolder.resetFields();
    setIsModalVisible(false);
  };

  const createNewEmptyFolder = () => {
    let createFolderObject = {
      employee_id: userData._id,
      filename: createNewFolder.getFieldValue("filename"),
      uuid: uuidv4(),
      lastUpdatedAt: Date.now(),
      privilage: "public",
      folder: "-",
      type: "folder",
    };

    console.log("add", createFolderObject);

    createNewFolder.resetFields();
    setIsModalVisible(false);
  };

  const createFolder = () => {
    return (
      <>
        <Modal
          centered
          title={
            <div
              style={{
                padding: "15px",
                backgroundColor: "#343642",
                color: "white",
                minHeight: "25px",
                maxHeight: "25px",
              }}
            >
              Create a folder
            </div>
          }
          closeIcon={<CustomCloseIcon />}
          open={isModalVisible}
          onOk={createNewEmptyFolder}
          onCancel={hideModal}
          footer={[
            <div style={{ padding: "16px" }}>
              <Button
                key="back"
                onClick={hideModal}
                style={{ marginRight: "10px" }}
              >
                Cancel
              </Button>

              <Button
                key="submit"
                onClick={createNewEmptyFolder}
                type="primary"
              >
                Create
              </Button>
            </div>,
          ]}
        >
          <Form
            layout="vertical"
            form={createNewFolder}
            style={{
              marginLeft: "15px",
              marginRight: "10px",
              marginTop: "10px",
            }}
          >
            <Form.Item label="Folder Name" name="filename">
              <Input
                onChange={(event: any) => {
                  const value = event.target.value
                    .replace(/^ /, "")
                    .replace(/\s+/g, " ");
                  createNewFolder.setFieldValue("filename", value);
                }}
              />
            </Form.Item>
          </Form>
        </Modal>
      </>
    );
  };

  const DropdownButton = () => {
    const item = {
      name: "file",
      customRequest: ({ file, onSuccess, onError, onProgress }: any) => {
        console.log("file", file); // Logs the file correctly
        const formData = new FormData();
        const filename = file.name;
        console.log("filename", filename);
        const imgUUID = uuidv4() + "." + filename.split(".").pop();
        let createFolderObject = {
          employee_id: userData._id,
          filename: filename,
          uuid: imgUUID,
          lastUpdatedAt: Date.now(),
          privilage: "public",
          folder: "-",
          type: "file",
        };
        console.log("createFolderObject", createFolderObject);
        // addExplorerItem(formData)
        //   .then((data: any) => {})
        //   .catch((error: any) => {});
      },
      multiple: true,
      // itemRender: () => null, // Hide the upload item list
      itemRender: (
        originNode: React.ReactElement,
        file: any,
        fileList: any
      ) => {
        return (
          <div>
            <div>File Name: {file.name}</div>
          </div>
        );
      },
    };

    const itemDirectory = {
      name: "file",
      action: "/upload", // Your server upload endpoint
      multiple: true,
      // itemRender: () => null, // Hide the upload item list
      itemRender: (
        originNode: React.ReactElement,
        file: any,
        fileList: any
      ) => {
        // Get the directory name from the first file's path
        // const firstFile = fileList.length > 0 ? fileList[0] : null;
        const firstFile = fileList[0].originFileObj;
        const relativePath = firstFile.webkitRelativePath;
        console.log("firstFile", firstFile);
        console.log("relativePath", relativePath);
        return null;
      },
    };

    const menu = (
      <Menu>
        <Menu.Item key="1" onClick={showModal}>
          <FolderOutlined />
          &nbsp; Folder
        </Menu.Item>
        <Menu.Item key="2">
          <Upload {...item} multiple>
            <FileAddOutlined />
            &nbsp; Files upload
          </Upload>
        </Menu.Item>
        <Menu.Item key="3">
          <Upload {...itemDirectory} directory>
            <FolderAddOutlined />
            &nbsp; Folder upload
          </Upload>
        </Menu.Item>
      </Menu>
    );

    return (
      <>
        <Dropdown overlay={menu}>
          <Button>+ Add new</Button>
        </Dropdown>
        {createFolder()}
      </>
    );
  };

  return (
    <>
      <div className="generic-container" style={{ gap: "20px" }}>
        <Space direction="vertical">
          <span
            style={{
              fontSize: "20px",
              fontWeight: "600",
              color: "rgba(0, 0, 0, 0.85)",
            }}
          >
            {props.params.propTitle}
          </span>
        </Space>
        <div
          style={{
            flex: "1",
            height: "100%",
            display: "flex",
            maxWidth: "100%",
            overflow: "hidden",
            flexDirection: "column",
          }}
        >
          {" "}
          <div
            className="generic-table-header-2"
            style={{ display: "flex", flexDirection: "row" }}
          >
            <span
              style={{
                // marginRight: "auto",
                minHeight: "32px",
                display: "flex",
                alignItems: "center",
              }}
            ></span>
            <DropdownButton></DropdownButton>
          </div>
          <div
            id="table-container"
            style={{ flex: 1, maxHeight: "100%", overflow: "hidden" }}
          >
            {/* {ready && (
                <Table
                  rowClassName={(record: any) =>
                    record?.uuid === activeRowKey ||
                    `${record.group_uuid}-${record.tag_uuid}` ===
                      activeGroupKey ||
                    (record.group_uuid === activeRowKey && !record.uuid)
                      ? "antd-table-row-active"
                      : ""
                  }
                  pagination={{
                    defaultPageSize: 20,
                    pageSizeOptions: [20, 50, 100],
                    showSizeChanger: true,
                    position: ["bottomCenter"],
                  }}
                  columns={tableColumns}
                  expandable={{
                    expandedRowKeys: expandedRowKeys,
                    onExpand: (expand: any, record: any) => {
                      let keys = [...expandedRowKeys];
                      if (expand) {
                        keys.push(record.uuid);
                      } else {
                        let index = keys.findIndex(
                          (item: any) => item == record.uuid
                        );
                        if (index != -1) {
                          keys.splice(index, 1);
                        }
                      }
                      setExpandRowKey(keys);
                    },
                  }}
                  scroll={tableHeight && { y: tableHeight, x: "max-content" }}
                  dataSource={filteredDataTable || dataTable}
                />
                
              )} */}
            <Table></Table>
          </div>
        </div>
      </div>
    </>
  );
}

export default ExplorerContainer;
